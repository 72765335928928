const { Howl, Howler } = require('howler');

Howler.autoUnlock = false;
Howler.mobileAutoEnable = false;

export const sounds = { "back-standing.mp3":null,
"buzzer-sounds.mp3":null,
"clinch-attempt.mp3":null,
"crowd-reaction.mp3":null,
"crowd-reaction2.mp3":null,
"crowd-reaction3.mp3":null,
"follow-ground.mp3":null,
"gain-top.mp3":null,
"gnp-blocked.mp3":null,
"gnp-crushing-old.mp3":null,
"gnp-crushing.mp3":null,
"gnp-punch-blocked.mp3":null,
"gnp-punch-shrugs.mp3":null,
"gnp-punch-solid.mp3":null,
"gnp-punch-solid1.mp3":null,
"gnp-punch-solid2.mp3":null,
"gnp-punch-solid3.mp3":null,
"gnp-shrugs.mp3":null,
"gnp-solid.mp3":null,
"gnp-vicious.mp3":null,
"kick-crushing.mp3":null,
"kick-miss.mp3":null,
"kick-shrugs.mp3":null,
"kick-solid.mp3":null,
"kick-vicious.mp3":null,
"knee-blocked.mp3":null,
"knee-crushing.mp3":null,
"knee-shrugs.mp3":null,
"knee-solid.mp3":null,
"knee-vicious.mp3":null,
"knock-down.mp3":null,
"knock-out.mp3":null,
"missed-badly.mp3":null,
"pass-complete.mp3":null,
"pass-defense.mp3":null,
"punch-blocked.mp3":null,
"punch-blocked2.mp3":null,
"punch-crushing.mp3":null,
"punch-miss1.mp3":null,
"punch-miss2.mp3":null,
"punch-partially.mp3":null,
"punch-shrugs.mp3":null,
"punch-solid1.mp3":null,
"punch-solid2.mp3":null,
"punch-vicious.mp3":null,
"stadium.mp3":null,
"stop-break.mp3":null,
"sweep-complete.mp3":null,
"sweep-defense.mp3":null,
"takedown-defense.mp3":null,
"takedown-dropped.mp3":null,
"takedown-slammed-head-first.mp3":null,
"takedown-slammed.mp3":null,
"takedown-tumbled.mp3":null,
"tap-out1.mp3":null,
"tap-out2.mp3":null

}

for (const sound in sounds)
{
    sounds[sound] = new Howl({
        src: ['/sounds/'+sound],
        html5:true,
        mute:false,
        mobileAutoEnable: false,
        xhr: {
          method: 'GET',
          headers: {},
          timeout: 0,
          responseType: 'arraybuffer'
        },
      
  onplayerror: function() {
    sound.once('unlock', function() {
      sound.play();
    });
  }
      })
      
}
console.log(sounds)