import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LOGIN, UPDATE_EMAIL, UPDATE_NAME, UPDATE_MY_FIGHTERS } from '../utils/actions';
import { NEW_VISIT } from '../utils/mutations';
import React, {Component} from 'react';
import { useMutation } from '@apollo/react-hooks';
import {withRouter, Route, Switch} from 'react-router-dom'
import moment from 'moment';
import Sim from './Sim';
import Selector from './Selector'
import Creator from './Creator'
import Edit from './Edit'
import Login from './Login'
import Card from './Card'
import Record from './Record'
import Rankings from './Rankings'
import Home from './Home'
import Winners from './Winners'
import Contact from './Contact'
import Forgot from './Forgot'
import Moves from './Moves'

const ObjectId = require('mongoose').Types.ObjectId;

/**
 * COMPONENT
 */
function Routes() {
    
   const state = useSelector((state) => {
    return state
     });

   const {isLoggedIn,myFighters} = state;

   const dispatch = useDispatch();

   const [newVisit, { data, loading, error }] = useMutation(NEW_VISIT);


   String.prototype.toObjectId = function () {
    if (this == "") return null
    else return new ObjectId(this.toString())
  };
  

   const updateVisit = async(date,_id) =>
   {

    const now = moment();
    const lastActive = moment(date);
    const inactiveDays = now.diff(lastActive, 'days');

    console.log("INACTIVE DAYS", inactiveDays, _id)

    if(inactiveDays > 0 || !_id || _id == undefined)
    {
      try {
        const response = await newVisit({
          variables: {
            ID:_id.toObjectId(), // This is how you pass the parameter to your mutation
          },
        });
        // Handle the response as needed
        console.log(response.data);
        return response.data
      } catch (error) {
        // Handle the mutation error
        console.error(error);
      }
    
    }
    else return false

   }
  
   const loggedIn = () =>
{

    fetch("/api/user/me")
    .then(data=>  data.json() )
    .then(user=> {
    dispatch({
        type: UPDATE_LOGIN,
        isLoggedIn : user != undefined && user.name && user._id && user.email ? true : false
      });
    dispatch({
        type: UPDATE_EMAIL,
        email : user != undefined && user.email ? user.email : null
      });
    dispatch({
        type: UPDATE_NAME,
        name : user != undefined && user.name ? user.name : null
      });
    dispatch({
        type: UPDATE_MY_FIGHTERS,
        myFighters : user != undefined && user.fighters ? user.fighters : null
      });

    updateVisit(user.visit,user._id)
    .then(res=>console.log(res))
    .catch(err=>console.log(err))
  

    })
    .catch(err=>console.log(err))
      
}
 console.log("state is",state, "logged in is",isLoggedIn)
 if(isLoggedIn == undefined) loggedIn()


    return (
      <Switch>

              <Route exact path="/" component={Selector} className="App" />
              <Route exact path="/sim" component={Sim} />
              <Route exact path="/card" component={Card} />
              <Route exact path="/record" component={Record} />
              <Route exact path="/rankings" component={Rankings} />
              <Route exact path="/home" component={isLoggedIn ? Home : Login} />
              {isLoggedIn && (<Route exact path="/create" component={Creator} />)} 
              {isLoggedIn && (<Route exact path="/edit" component={Edit} />)} 
              {isLoggedIn && (<Route exact path="/moves" component={Moves} />)} 
              {/* <Route exact path="/create" component={Creator} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Login} />
              <Route exact path="/winners" component={Winners} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/forgot" component={Forgot} />
       
      </Switch>
    )
  
}

export default Routes

