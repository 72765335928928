import styled, { keyframes } from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_FIGHTER1,UPDATE_FIGHTER2} from '../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import React,{ useEffect } from 'react';

// auction bid 
function FighterBtn(prop) {
  
    const { _id, column, NAME } = prop;



    const state = useSelector((state) => {
        return state
    });

    const dispatch = useDispatch();
    

    function selectFighter(event){


        if(column == 1)
        {
            localStorage.setItem('fighter1',_id.toString());
            state.fighter1 = _id;
            
        }
        else if(column == 2)
        {
            localStorage.setItem('fighter2',_id.toString());
            state.fighter2 = _id;

            if (state.fighter1 && state.fighter2) scrollToBottom(); // && rounds && sims)
     
        }

        changeColor(event.target);

        
    }

    function changeColor(el)
    {

        document.querySelectorAll('.column-div-'+column).forEach(node => {

            console.log(node.id,_id,column)
            if(node.id == _id+column)
            {
                node.classList.add("button-pinkgold");
           // node.style.backgroundColor = "#000";
            //node.style.color = "orange";
           // node.style.textShadow = "0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black"
            }
            else
            {
                node.classList.remove("button-pinkgold")
              //  node.className = '.column-div-'+column+" button-platinum"
              //  node.style.backgroundColor = "organge";
               // node.style.color = "#000";
               // node.style.textShadow = "1px 1px #fff"
            }

        });
    
    }

    function scrollToBottom() {
        const scrollHeight = document.body.scrollHeight;
        const duration = 2000; // Animation duration in milliseconds
        
        const start = window.pageYOffset; // Starting position
        const change = scrollHeight - start; // Change in position
        let startTime = null;
      
        function animateScroll(timestamp) {
          if (!startTime) startTime = timestamp;
      
          const elapsedTime = timestamp - startTime;
          const progress = Math.min(elapsedTime / duration, 1); // Animation progress between 0 and 1
          const easedProgress = easeInQuad(progress); // Apply easing function
 
          const newPosition = start + (change * easedProgress);
      
          window.scrollTo(0, newPosition);
      
          if (progress < 1) {
            // Continue animating
            requestAnimationFrame(animateScroll);
          }
        }
      
        // Start the animation
        requestAnimationFrame(animateScroll);
      }
    
      function easeInQuad(progress) {
        return progress * progress;
      }
 
    const H3 = styled.h3`
    font-size:14px;
    border-radius: 10px;
    text-align:center;
    margin-left:7px;
    margin-right:7px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
    min-width: 120px;
    padding-left:3px;
    padding-right:3px;
    @media (max-width:1000px) {
        margin-left:15px; 
    }

    `;

    const H4 = styled.h4`
    margin-left:15px;
    font-size:14px;
    @media (max-width:1000px) {
        margin-left:15px; 
    }
    `;

   

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    height: 20px;
    margin-left:35px;
    outline:none;
    cursor:pointer;
    background-color: #00A598;
    box-shadow: 3px 3px;
    white-space: nowrap;
    `;

  
        return (      
                        <H3 onClick={selectFighter} className={"column-div-"+column+" button-platinum"} id={_id+column}>{NAME}</H3>
        )
    }


export default FighterBtn;