/* eslint-disable complexity */
import React from 'react'
import '../Forgot.css'
import {Link} from 'react-router-dom'

/**
 * COMPONENT
 */
const Forgot = props => {

  console.log("search is",props.location.search.split("token=")[1])
  const token = props.location.search.split("token=")[1];

const handleSubmit = evt => {
    evt.preventDefault();

    const email = evt.target.email.value;
    
   fetch('api/mail/forgot',{
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email:email})
  })
    .then(data=>{
         document.querySelector("#form-forgot").style.display = "none";
         document.querySelector("#title-div").textContent = "A password reset link has been sent to your email. If it doesn't appear on your inbox, please check your spam folder."
    })
    .catch(err=>alert(err))
}

const handleReset = evt => {
    evt.preventDefault();
    const rgex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    const password = evt.target.password.value;

    if(!rgex.test(evt.target.password.value))
    {
      alert("You password must contain at least 8 total characters. 1 uppercase character; 1 lowercase character; and 1 digit character.")
      return
    }
    if(evt.target.password_confirm.value != evt.target.password.value)
    {
      alert("Password and confirm password fields don't match!")
      return
    }

    console.log(password,"is password")

   fetch('api/mail/reset',{
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token:token,password:password})
  }) 
   .then(({data})=>{
    console.log(data)
    document.querySelector("#form-forgot").style.display = "none";
    document.querySelector("#title-div").textContent = "Your password has been reset. You will now be redirected to the login page."

   setTimeout(redirect,4000,data)
   })
   .catch(err=>console.log("Something went wrong", err.message))

}

const redirect = () => { window.location = "/" }

  return ( 
    <div className="container_forgot">
    <main role="main" className="main-content" id="main-content">
    <h3 id="title-div" className="text-pink">Forgot your Password?</h3>
    { token != undefined ? (
         <form id="form-forgot"
         className="form"
         onSubmit={handleReset}>
         <div>
           <label htmlFor="password">
             <small>Enter new password:</small>
           </label><br></br>
           <input name="password" type="password" />
         </div>
         <div>
           <label htmlFor="password_confirm">
             <small>Confirm new password:</small>
           </label><br></br>
           <input name="password_confirm" type="password" />
         </div>
         <div style={{width:"100px",margin:"auto"}}>
        
                   <button
                     type="submit"
                     variant="contained"
                     color="secondary"
                     size="small"
                   >
                     RESET
                   </button>
        
         </div>
         </form>
        
        ) : (
    <form
    className="form"
    id="form-forgot"
    onSubmit={handleSubmit}>
    <div>
      <label htmlFor="email">
        <small>Enter your email address:</small>
      </label><br></br>
      <input name="email" type="text" />
    </div>
    <div style={{width:"100px",margin:"auto"}}>

              <button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
              >
                SUBMIT
              </button>
    
    </div>
    </form>
    )}
    </main>
    </div>
  
    )

}

  
  //export default ModeratorDashboard
export default Forgot


