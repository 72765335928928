import './App.css';
import ReactDOM from 'react-dom'
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { Provider } from 'react-redux'
import Routes from './components/Routes'
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import store from "./redux/store";
// import isLoggedIn from "./utils/loggedIn";
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LOGIN } from './utils/actions';

function App () {
  
  const client = new ApolloClient({
    request: (operation) => {
     // const token = localStorage.getItem('id_token')
      operation.setContext({
        headers: {
    //      authorization: token ? `Bearer ${token}` : ''
        }
      })
    },
    uri: '/graphql',
  })

  
  const Header = styled.header`
  font-size: 6px;
  display: flex;
  justify-content: center;
  align-items:center;
  align-content:center;
  border-bottom: 1px solid #ccc;
  `

  return (
    <ApolloProvider client={client}>
      <Router>
        <div >
          <Sidebar/>
          <Navbar/>
        {/* <Header><span className="metallic">NFC </span><span className="metallic"> MMA</span></Header> */}
           <Provider store={store}> 
           
            <Routes>
         
            </Routes>
           </Provider> 
        </div>
      </Router>
    </ApolloProvider>
  );

}

export default App;
