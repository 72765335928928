import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import '../Contact.css'


export default function Contact() {
  const [user, setUser] = useState({})

  const state = useSelector((state) => {
    return state
});

const {email, name} = state;


//   getEmail()
//     .then(userData => setUser(userData))
//     .catch(err => console.log(err))

  const handleSubmit = event => {
    event.preventDefault()

    const email = event.target.email.value
    const title = event.target.title.value
    const message = event.target.message.value
    const name = event.target.name.value

    const body = {
      name: name,
      email: email,
      title: title,
      message: message
    }

    fetch('/api/mail/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(res => {
        console.log(res)
        document.querySelector('#container').innerHTML =
          'Thank you for contacting Us. Our team has received your message and will reply shortly.'
        setTimeout(() => {
         // window.location = '/'
        }, 5000)
      })
      .catch(err => {
        console.log(err)
        alert('error')
      })
  }

  return (
    <div className="contact">
      <div id="container" className="container_contact">
        <form className="form-contact" onSubmit={handleSubmit}>
          <h2>Contact Us</h2>
          <div>
            <label htmlFor="fullname">
              <small>Full Name</small>
            </label><br></br>
            <input
              name="name"
              type="text"
              defaultValue={state.name != undefined ? state.name : ''}
            />
          </div>
          <div>
            <label htmlFor="email">
              <small>Email</small>
            </label><br></br>
            <input
              name="email"
              type="text"
              defaultValue={state.email != undefined ? state.email : ''}
            />
          </div>
          <div>
            <label htmlFor="title">
              <small>Subject</small>
            </label><br></br>
            <input name="title" id="title" />
          </div>
          <div>
            <label htmlFor="message">
              <small>Message</small>
            </label><br></br>
            <textarea name="message" id="message" />
          </div>
         
            <button
              style={{width: '250px', margin: 'auto'}}
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
            >
              SUBMIT
            </button>
        
        </form>
      </div>
    </div>
  )
}
