import styled, { css } from 'styled-components';
import { QUERY_FIGHTERS,QUERY_RANKINGS } from "../utils/queries";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';
const ObjectId = require('mongoose').Types.ObjectId;

function Rankings(props) {
  
  const state = useSelector((state) => {
    return state
  });

  const { email, isLoggedIn, myFighters} = state;

  const dispatch = useDispatch();
  
  const {data:fighters_data} = useQuery(QUERY_FIGHTERS) 

  const {data:rankings_data} = useQuery(QUERY_RANKINGS) 
  
  
  useEffect(() => {

     if(fighters_data) {
         
         setFighters(fighters_data.fighters.map(f=> ( {_id:f._id, NAME:f.NAME })))
    
     }


    }, [fighters_data]);

  useEffect(() => {

        if(rankings_data) {

            rankings_data.rankings.sort((a,b)=>a.RATING-b.RATING)

        }
   
   
       }, [rankings_data]);

  const [fighters, setFighters] = useState([]);

  const getName = (id) => {

    let i = 0;

   // if(fighters.length>0)console.log(fighters)

    while (i < fighters.length)
    {
       if(id.toString()==fighters[i]._id.toString()) return fighters[i].NAME
       i++
    }

  }

  const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    `;

  const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const H7 = styled.div`
    display:flex;
    justify-content:space-between;
    font-size:28px;
    margin-top:15px;
    `;

  const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const IncrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:4px;
    padding-right:4px;
    `;

    const DecrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:6px;
    padding-right:4px;
    `;

  const Div = styled.div`
    display:flex;
    justify-content:center;
    font-size:20px;
    width: 225px;
    text-align:center;
    `;

  const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

 

  return (

    <header className="App-header">
      <Wrapper>

        <H7 className="metallic">MMA RANKINGS</H7>

        <Container>

        {rankings_data && (rankings_data.rankings.sort((a,b)=>b.RATING-a.RATING).map((rank,index)=>(

            <ViewBtn onClick={()=>{window.location = "/record?fighter="+rank.FIGHTER_ID.toString()} }className={index<= 9?"button-whitegold":"button-platinum"} style={{width:"320px"}}>
                
                {(index+1)+" - "+getName(rank.FIGHTER_ID)+" - "+ Math.floor(rank.RATING) }
                
            </ViewBtn>
            

        )))}
      

        </Container>

       


      </Wrapper>
    </header>
  )
}

export default Rankings;