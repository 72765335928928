import styled, { css } from 'styled-components';
import { QUERY_MY_FIGHTERS } from "../utils/queries";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';

function Edit(props) {


  
  const state = useSelector((state) => {
    return state
  });

  const { email, isLoggedIn, myFighters} = state;

  const currentFighter = props.location.search.split("fighter=")[1] || null;


  //if(!currentFighter || currentFighter == undefined) window.location = "/home"


  
  const dispatch = useDispatch();
  
  

  
  useEffect(() => {

     
     


    }, []);

 

  const changeStatus = (event) =>  {

    const body = {};
    
    body.type = "GAME";

    fetch(`api/fighter/activate`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(result => window.location = "/")
      .catch(err => console.log(err, event))
  }

  const submit = (event) => {

      event.preventDefault()
    const body = {}


    event.target.setAttribute("data-locked", true)

    fetch(`api/fighter`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(result => window.location = "/")
      .catch(err => console.log(err, event))

  }

  
  const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    `;

  const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

  const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const IncrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:4px;
    padding-right:4px;
    `;

    const DecrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:6px;
    padding-right:4px;
    `;

    const Div = styled.div`
    display:flex;
    font-weight:600;
    justify-content:space-between;
    text-shadow: 1px 1px #000;
    width: 100%;
    `;

  const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

    const Text = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    padding:5px 15px 5px 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    text-align: center;
    text-style:bold;
    font-size:16px;
    background-color: rgba(0,0,0,0.5);
    `;

 

  return (

    <header className="App-header">
      <Wrapper>

        <h3>FIGHTER EDITOR</h3>

        <ViewBtn className="button-platinum" id="gopro-btn">Moves List</ViewBtn>
     
        <Container>

            <form onSubmit={submit}>

        <Div>
            <label htmlFor="XP">XP</label>  
            <input className="stat-class" name="XP" id="XP" type="Number" width="40px"  readOnly={true} value  min="3" max="10" style={{ marginLeft: "10px",marginBottom:"15px" }}></input>      
        </Div>
          <input name="NAME" id="NAME" type="text" value readOnly={true} onChange style={{textAlign:"center",fontSize:"18px"}}></input>
          <h3>Physical</h3>
          <Div>
            <label htmlFor="STRENGTH">Strength</label>
    
          </Div>

          <Div>
            <label htmlFor="KO_POWER">KO Power</label>
          </Div>

          <Div>
            <label htmlFor="CONDITIONING">Conditioning</label>
          </Div>


          <ViewBtn className="button-platinum" id="submit-btn" data-locked={false} type="submit">Submit</ViewBtn>

          </form>


        </Container>

       


      </Wrapper>
    </header>
  )
}

export default Edit;