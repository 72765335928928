import styled, { css } from 'styled-components';
import { QUERY_WINNERS } from "../utils/queries";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_WINNERS } from '../utils/actions';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';




// This files contains content and UI logic for main part of the UI showing the product and the details

function Winners(props) {

    const state = useSelector((state) => {
        return state
    });

    const {winners,startDate} = state;

    const dispatch = useDispatch();
  

    const PAGE = props.location.search.split("page=")[1] || 0;


    const { loading:winners_loading, data:winners_data } = useQuery(QUERY_WINNERS);

    
    useEffect(() => {

        const WINNERS = {};

      
    
        if(winners_data) {

            const {winners} = winners_data;
           
            winners.forEach(win=>{

                const id = win.WINNER_ID.toString();

                if(id in WINNERS)
                {
                    WINNERS[id].push(win)
                }
                else
                {
                    WINNERS[id] = [win];
                }


            })      
            
            console.log(Object.entries(WINNERS))

            dispatch({
                type: UPDATE_WINNERS,
                winners: Object.entries(WINNERS)
              });
                
       
        } 


      }, [winners_data, winners_loading, dispatch]);


    function daysElapsed(createdAt,start)
    {
       const cardDate = moment(parseInt(createdAt)) 

       const dur = moment.duration(cardDate.diff(start))
        
       console.log(moment(parseInt(start)).format())

       return Math.floor(dur.asDays())+1;
    }

    function pageNumber(createdAt, start)
    {

        const today = moment();
        const cardDate = moment(parseInt(createdAt))
        const elapsed = moment.duration(cardDate.diff(start))
        const page = moment.duration(today.diff(cardDate))
        
        return Math.floor(page.asDays()) +(Math.floor(elapsed.asDays()) == 0 ? 1 : 0);
    }

    function parsed(json,id)
    {
        console.log(json,id)
    }

    const draw = async(fighter,id) => {

        if(fighter == undefined || !id == undefined) return

        const bodyFiles = await JSON.parse(fighter);

        const canvas = document.getElementById(id);
        
        const ctx = canvas.getContext("2d");
        
        const bodyParts = {
        bodyImg : new Image(),
        faceImg : new Image(),
        mouthImg : new Image(),
        bodyHairImg : new Image(),
        tattooChestImg : new Image(),
        tattooBicepRightImg : new Image(),
        tattooForearmRightImg : new Image(),
        tattooForearmLeftImg : new Image(),
      //  tattooShoulderRightImg : new Image(),
        tattooShoulderLeftImg : new Image(),
        tattooShinRightImg : new Image(),
        tattooShinLeftImg : new Image(),
        tattooHeadImg : new Image(),
        shortsImg : new Image(),
        hairImg : new Image(),
        beardImg : new Image()
        }
    
       
        let imgsLoaded = 0;
        const imgsArray = [];
    
        for (const part in bodyParts)
        {
        const file = bodyFiles[part.slice(0, -3) +"_file"]
        const img = bodyParts[part]
    
        if(!file.includes("none"))
        {
        img.src = file
        imgsArray.push(img)
        }
        else imgsLoaded++;
    
        img.onload = function () {
        
         // 
         imgsLoaded++;
         if(imgsLoaded >= 14)
         {
           ctx.save();
           ctx.translate(-80,0);
           ctx.scale(-0.2,0.2)
         
          
            imgsArray.forEach(img=> ctx.drawImage(img, img.width * -1.3, 0))
            ctx.restore();
         
         }
        }
      }
    
      
    
      }

  

    const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    min-width:300px;
    align-items:center;
    font-size:12px;
    `;

    const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    margin-top:10px;
    margin-bottom:10px;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const Span = styled.span`
    width:100%;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    white-space: nowrap;
    font-size:18px;
    font-weight:600;
    text-shadow:1px 1px #000
    `;

 

    return (

            <div>
            <Wrapper>
                <h4>All-time Winners</h4>
                {
              winners.map(win=> 
                <Container id={win[1][0].WINNER_ID}>
                <canvas onClick={()=>{window.location = "/record?fighter="+win[1][0].WINNER_ID}} id={"canvas"+win[1][0].WINNER_ID} data-img={draw(win[1][0].WINNER_BODY,"canvas"+win[1][0].WINNER_ID)} height={220} width={150}></canvas>
                <Span onClick={()=>{window.location = "/record?fighter="+win[1][0].WINNER_ID}}>{win[1][0].WINNER_NAME}<br></br>{" - "+win[1].length+" Tournament Win(s)"}</Span>
                {
                    win[1].map(tour=>

                        <Container onClick={()=>{window.location = "/card?page="+pageNumber(tour.createdAt,startDate)}} style={{fontSize:"24px"}} className="metallic" key={tour.createdAt}>NFC {daysElapsed(tour.createdAt,startDate)} </Container>

                    )

                }
                </Container>

            )
                }


            </Wrapper>
              </div>

       
    )
}

export default Winners;