import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
// import {makeStyles} from '@material-ui/core'



export function Navbar({isLoggedIn}) {
  const [url, setUrl] = useState(window.location.href)

  const toggleSidebar = event => {
    const sidebarX = document.querySelector('.sidebar-nav').style.left

    if (sidebarX == 0 || sidebarX == '0px') {
      document.querySelector('.sidebar-nav').style.left = '-100vw'

    } else {
      document.querySelector('.sidebar-nav').style.left = 0
  
    }
  }

  return (
    <div>
      {window.innerWidth <= 1000 ? (
        <div
          className="navbar"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}
        >
        <FaBars className="barLink-mobile" style={{visibility: "hidden"}} />
        <div style={{paddingLeft:"20px"}}><span className="metallic">NFC </span><span className="metallic"> MMA</span></div>
        <FaBars className="barLink-mobile" onClick={toggleSidebar} />
        </div>
      ) : isLoggedIn ? (
        <div className="navbar">
        <div style={{paddingLeft:"20px"}}> <span className="metallic">{window.innerWidth < 900 ? "NFC MMA" : "NON FUNGIBLE CHAMPIONSHIPS"}</span></div>
        <Link  onClick={() => setUrl('/contact')}
            className={
              url.includes('contact') ? 'barLink2-selected' : 'barLink2'
            } to="/contact">
            CONTACT
          </Link>
          <Link onClick={() => setUrl('/login')}
            className={
              url.includes('login') ? 'barLink2-selected' : 'barLink2'
            } to="/login">
            LOGIN
          </Link>
          <Link  onClick={() => setUrl('/winners')}
            className={
              url.includes('winners') ? 'barLink2-selected' : 'barLink2'
            } to="/winners">
            ALL-TIME WINNERS
          </Link>
          <Link
            to="/rankings"
            onClick={() => setUrl('/rankings')}
            className={
              url.includes('rankings') ? 'barLink2-selected' : 'barLink2'
            }
          >
            RANKINGS
          </Link>
          <Link
            to="/card"
            onClick={() => setUrl('/card')}
            className={
              url.includes('card') ? 'barLink2-selected' : 'barLink2'
            }
          >
            RESULTS
          </Link>
          <Link
            to="/home"
            onClick={() => setUrl('/home')}
            className={
              url.includes('home') ? 'barLink2-selected' : 'barLink2'
            }
          >
            HOME
          </Link>
        </div>
      ) : (
        <div className="navbar">
          {/* <Link to="/" className="barLink">
            SHINFLIK
          </Link> */}
        <div style={{paddingLeft:"20px"}}> <span className="metallic">{window.innerWidth < 900 ? "NFC MMA" : "NON FUNGIBLE CHAMPIONSHIPS"}</span></div>
        <div>
        <Link  onClick={() => setUrl('/contact')}
            className={
              url.includes('contact') ? 'barLink2-selected' : 'barLink2'
            } to="/contact">
            CONTACT
          </Link>
          <Link onClick={() => setUrl('/login')}
            className={
              url.includes('login') ? 'barLink2-selected' : 'barLink2'
            } to="/login">
            LOGIN
          </Link>
          <Link  onClick={() => setUrl('/winners')}
            className={
              url.includes('winners') ? 'barLink2-selected' : 'barLink2'
            } to="/winners">
            ALL-TIME WINNERS
          </Link>
          <Link
            to="/rankings"
            onClick={() => setUrl('/rankings')}
            className={
              url.includes('rankings') ? 'barLink2-selected' : 'barLink2'
            }
          >
            RANKINGS
          </Link>
          <Link
            to="/card"
            onClick={() => setUrl('/card')}
            className={
              url.includes('card') ? 'barLink2-selected' : 'barLink2'
            }
          >
            RESULTS
          </Link>
          <Link
            to="/home"
            onClick={() => setUrl('/home')}
            className={
              url.includes('home') ? 'barLink2-selected' : 'barLink2'
            }
          >
            HOME
          </Link>
          </div>
        </div>
      )}
      {/* <hr/> */}
    </div>
  )
}

export default Navbar

