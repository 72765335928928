import styled, { css } from 'styled-components';
import { QUERY_FIGHTS,QUERY_CARDS,QUERY_MY_FIGHTERS } from "../utils/queries";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FIGHTS,UPDATE_CARDS,UPDATE_CARD_TITLE,UPDATE_CARD_SIZE, UPDATE_CURRENT_FIGHTER } from '../utils/actions';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';
import { Bracket, RoundProps } from 'react-brackets';
import CustomSeed from './CustomSeed'



// This files contains content and UI logic for main part of the UI showing the product and the details

function Card(props) {

    const state = useSelector((state) => {
        return state
    });

    const {name, email, isLoggedIn, myFighters} = state;

    const dispatch = useDispatch();
  
    const {data:fighter_data} = useQuery(QUERY_MY_FIGHTERS , {
        variables: { skip:myFighters==null||myFighters == undefined||myFighters.length==0 ? true : false,fighters:myFighters||[]},
      }) 

    
    useEffect(() => {

       if(fighter_data) {
           console.log(fighter_data)
           const {myFighters} = fighter_data; console.log(fighter_data);

           myFighters.forEach(f=>{

            draw(f)
            
           })
       }


      }, [fighter_data]);

    function gotoCreate(event)
    {
        window.location = "/create"
    }

    function gotoEdit(event)
    {
      window.location = "/edit?fighter="+event.target.id;
    }

    function gotoRecord(event)
    {
      window.location = "/record?fighter="+event.target.id;
    }

    function changeColor(selected,unselected)
    {

        document.getElementById(selected).style.color = "#fff";
        document.getElementById(selected).style.backgroundColor = "#000";

        document.getElementById(unselected).style.color = "#000";
        document.getElementById(unselected).style.backgroundColor = "#00A598";
       
    }

    const printStats = (fighter,dir) =>
    {
      const { STRENGTH, KO_POWER, CONDITIONING, CHIN,  KICKS,  PUNCHES, CLINCH,  TAKEDOWNS,
        SPRAWL,  AGGRESIVENESS,  KILLER_INSTINCT,  ECCENTRICITY,    GROUND_AND_POUND,   SUBMISSIONS,   GUARD,   SCRAMBLES} = fighter;

      if(dir=="left") return `STRENGTH: ${STRENGTH}<br></br>KO_POWER: ${KO_POWER}<br></br>CONDITIONING: ${CONDITIONING}<br></br>CHIN: ${CHIN}<br></br>KICKS: ${KICKS}<br></br>PUNCHES:${PUNCHES}<br></br>CLINCH: ${CLINCH}<br></br>TAKEDOWNS: ${TAKEDOWNS}`;
      else return `SPRAWL: ${SPRAWL}<br></br>AGGRESIVENESS: ${AGGRESIVENESS}<br></br>KILLER_INSTINCT: ${KILLER_INSTINCT}<br></br>ECCENTRICITY:${ECCENTRICITY}<br></br>GROUND_AND_POUND: ${GROUND_AND_POUND}<br></br>SUBMISSIONS: ${SUBMISSIONS}<br></br>GUARD:${GUARD}<br></br>SCRAMBLES: ${SCRAMBLES}`;

    }

    const draw = async(fighter) => {

        const bodyFiles = await JSON.parse(fighter.IMG);
        const id = fighter._id.toString()

        const wrapper = document.createElement('div');
        const canvas = document.createElement('canvas');
        const middle = document.createElement('div');
        const middleLeft = document.createElement('div');
        const middleRight = document.createElement('div');

        const bottom = document.createElement('div');
        const bottomLeft = document.createElement('div');
        const bottomRight = document.createElement('div');

        const name = document.createElement('div');

        wrapper.style.scrollSnapAlign = "center";
  
 
        canvas.style.borderRadius = "15px"; canvas.style.marginBottom = "10px"; canvas.style.background = "none"
        name.style.width="360px"; name.style.textAlign = "center"; name.style.fontSize = "20px"; name.className="metallic"; name.style.paddingTop = "20px"; name.style.paddingBottom = "10px"
        wrapper.style.display="flex"; wrapper.style.flexDirection="column"; wrapper.style.width="360px";wrapper.style.borderRadius="15px";wrapper.style.margin = "10px"; wrapper.style.border = "4px solid black"; wrapper.style.backgroundImage="url(/hexagon-shapes-on-the-rainbow-2022-04-25-10-25-20-utc_AdobeExpress.gif)"; wrapper.style.backgroundSize ="contain"; 
        middle.style.display="flex";middle.style.flexDirection="flex-start";middle.style.width="100%"; middle.className="button-platinum";middle.style.fontSize="10px";//middle.style.borderRadius="15px";
        middleLeft.style.display="flex";middleLeft.style.flexDirection="column";middleLeft.style.width="170px";middleLeft.style.height="100%";middleLeft.style.padding="10px";
        middleRight.style.display="flex";middleRight.style.flexDirection="column";middleRight.style.width="170px"; middleRight.style.height="100%";middleRight.style.padding="10px";
        bottom.style.display="flex";bottom.style.flexDirection="flex-start";bottom.style.width="100%"; bottom.style.backgroundColor="#333";bottom.style.fontSize="12px";bottom.style.marginBottom = "5px";
        bottomLeft.style.display="flex";bottomLeft.style.flexDirection="column";bottomLeft.style.width="170px";bottomLeft.style.height="100%";bottomLeft.style.padding="10px";bottomLeft.style.borderRight = "1px solid #000";bottomLeft.style.textAlign = "center"; bottomLeft.style.cursor = "pointer"; bottomRight.className = "button-silver"
        bottomRight.style.display="flex";bottomRight.style.flexDirection="column";bottomRight.style.width="170px"; bottomRight.style.height="100%";bottomRight.style.padding="10px"; bottomRight.style.textAlign = "center"; bottomRight.style.cursor = "pointer"; bottomLeft.className = "button-silver"
        bottomRight.onclick = gotoEdit;
        bottomLeft.onclick = gotoRecord;
        bottomRight.id = id;
        bottomLeft.id = id;

 
      
       // bottomLeft.innerHTML = '<p>AGGRESSIVENESS: 10</p><p>STRENGTH: 9</p><p>PUNCHES: 9</p><p>KICKS: 8</p><p>CHIN: 8.5</p>'
        middleLeft.innerHTML = printStats(fighter,"left")
        middleRight.innerHTML = printStats(fighter,"right")
        
        const ctx = canvas.getContext("2d");
        canvas.id = "canvas-"+id;
        //canvas.style.margin = "10px";
        canvas.height = 540;                         
        canvas.width = 360;
       // canvas.style.backgroundColor="#fff";

        middle.appendChild(middleLeft);
        middle.appendChild(middleRight);
        bottom.appendChild(bottomLeft);
        bottom.appendChild(bottomRight);
        wrapper.appendChild(name);
        wrapper.appendChild(canvas);
        wrapper.appendChild(middle);
        wrapper.appendChild(bottom);

        document.querySelector("#fighter_imgs").appendChild(wrapper);
        bottomRight.textContent = "MANAGE";
        bottomLeft.textContent = "RECORD";
        name.textContent = fighter.NAME.toUpperCase();
        
        const bodyParts = {
        bodyImg : new Image(),
        faceImg : new Image(),
        mouthImg : new Image(),
        bodyHairImg : new Image(),
        tattooChestImg : new Image(),
        tattooBicepRightImg : new Image(),
        tattooForearmRightImg : new Image(),
        tattooForearmLeftImg : new Image(),
      //  tattooShoulderRightImg : new Image(),
        tattooShoulderLeftImg : new Image(),
        tattooShinRightImg : new Image(),
        tattooShinLeftImg : new Image(),
        tattooHeadImg : new Image(),
        shortsImg : new Image(),
        hairImg : new Image(),
        beardImg : new Image()
        }
    
       
        let imgsLoaded = 0;
        const imgsArray = [];
    
        for (const part in bodyParts)
        {
        const file = bodyFiles[part.slice(0, -3) +"_file"]
        const img = bodyParts[part]
    
        if(!file.includes("none"))
        {
        img.src = file
        imgsArray.push(img)
        }
        else imgsLoaded++;
    
        img.onload = function () {
        
         // 
         imgsLoaded++;
         if(imgsLoaded >= 14)
         {
           ctx.save();
           ctx.translate(-200,0);
           ctx.scale(-0.5,0.5)
         
          
            imgsArray.forEach(img=> ctx.drawImage(img, img.width * -1.3, 0))
            ctx.restore();
         
         }
        }
      }
    
      
    
      }

    const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin-top:20px;
    `;

    const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    margin-top:10px;
    margin-bottom:10px;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const Scroller = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    margin-top:10px;
    margin-bottom:10px;
    align-items:center;
    color: #fff;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #000;
    height: 20px;
    width: 170px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #fff;
    white-space: nowrap;
    `;

    const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

 

    return (

            <div>
            <Wrapper className='metallic' style={{fontSize:"20px"}}>{name?.toUpperCase()}</Wrapper>
            <Wrapper>
            <div className='metallic' style={{fontSize:"20px"}}>My Fighters:</div>
            {(isLoggedIn && email && isLoggedIn != undefined && myFighters.length <= 3) ?
             (<ViewBtn className="button-platinum" onClick={gotoCreate}>Create New Fighter</ViewBtn>):
             ("")
            }
            <Scroller id="fighter_imgs" style={{flexDirection:"row",justifyContent:"flex-start"}}></Scroller>
            </Wrapper>
            </div>

       
    )
}

export default Card;