import styled, { css } from 'styled-components';
import { QUERY_FIGHTERS,QUERY_MY_FIGHTERS } from "../utils/queries";
import {  UPDATE_MY_FIGHTERS } from '../utils/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';
import stringSimilarity from 'string-similarity';

function Creator() {


  
  const state = useSelector((state) => {
    return state
  });

  const { email, isLoggedIn, myFighters} = state;

  const {data:fighter_data} = useQuery(QUERY_MY_FIGHTERS , {
    variables: { skip:myFighters==null||myFighters == undefined||myFighters.length==0 ? true : false,fighters:myFighters||[]},
  }) 


  const dispatch = useDispatch();

useEffect(() => {

   if(fighter_data) {
       console.log(fighter_data)
       const {myFighters} = fighter_data; console.log(fighter_data);

       myFighters.forEach((f,i)=>{

        myFighters[i] = f;
        
       } )

       console.log("my fighters object",myFighters)
       dispatch({
        type: UPDATE_MY_FIGHTERS,
        myFighters : myFighters != undefined && myFighters != null ? myFighters : null
      });
   }


  }, [fighter_data]);

  console.log("my fighters",myFighters)


  let canvas, ctx;

  useEffect(() => {
    canvas = document.querySelector("#appearanceCanvas")
    if(!canvas) return
    ctx = canvas.getContext("2d");
    ctx.scale(0.5, 0.5);
    draw();
  });

  const [stats, setStats] = useState({
    type: "GAME",
    NAME: "",
    IMG: "",
    WEIGHT_CLASSES: [],
    XP: 4,
    STRENGTH: 6,
    KO_POWER: 6,
    CONDITIONING: 6,
    CHIN: 6,
    KICKS: 6,
    PUNCHES: 6,
    CLINCH: 6,
    TAKEDOWNS: 6,
    SPRAWL: 6,
    AGGRESIVENESS: 6,
    KILLER_INSTINCT: 6,
    ECCENTRICITY: 6,
    GROUND_AND_POUND: 6,
    SUBMISSIONS: 6,
    GUARD: 6,
    SCRAMBLES: 6,
    STRAT1_STRIKING: 65,
    STRAT1_GRAPPLING: 35,
    STRAT2_CLINCH: 50,
    STRAT2_SHOOT: 50,
    STRAT3_GROUND_AND_POUND: 55,
    STRAT3_POSITIONING: 40,
    STRAT3_SUBMISSION: 5,
    STRAT3_STAND: 0,
    STRAT4_POSITIONING: 40,
    STRAT4_SCRAMBLING: 55,
    STRAT4_SUBMISSION: 5,
    STRAT5_PUNCHING: 35,
    STRAT5_KICKING: 65,
    STRAT6_PULL_GUARD: 0,
    STRAT6_STAND: 100
  })

  let XP = stats.XP;

  const bodyArray = ["jacked", "shredded", "skinny", "fat"];
  const skinArray = ["white", "black"];
  const faceArray = ["none","cauc","rus","asian","latin","nord","kraut"]
  const shortsArray = Array.from({length: 23}, (_, i) => i + 1)
  const hairArray = ["none"].concat(Array.from({length: 20}, (_, i) => i + 1))
  const beardArray = ["none"].concat(Array.from({length: 6}, (_, i) => i + 1))
  const mouthArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const bodyHairArray = ["none"].concat(Array.from({length: 1}, (_, i) => i + 1))
  const tattooChestArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const tattooBicepRightArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const tattooForearmRightArray = ["none"].concat(Array.from({length: 2}, (_, i) => i + 1))
  const tattooForearmLeftArray = ["none"].concat(Array.from({length: 2}, (_, i) => i + 1))
  const tattooShoulderRightArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const tattooShoulderLeftArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const tattooShinRightArray = ["none"].concat(Array.from({length: 2}, (_, i) => i + 1))
  const tattooShinLeftArray = ["none"].concat(Array.from({length: 2}, (_, i) => i + 1))
  const tattooHeadArray = ["none"].concat(Array.from({length: 3}, (_, i) => i + 1))
  const hairColorArray = ["brown","blonde","red"]
  const beardColorArray = ["brown","blonde","red"]
 

  let body = bodyArray[0];
  let skin = skinArray[0];
  let face = faceArray[0];
  let shorts = shortsArray[0];
  let hair = hairArray[0];
  let hairColor = hairColorArray[0];
  let beard = beardArray[0];
  let beardColor = beardColorArray[0];
  let mouth = mouthArray[0];
  let bodyHair = bodyHairArray[0];
  let tattooChest = tattooChestArray[0];
  let tattooBicepRight = tattooBicepRightArray[0];
  let tattooForearmRight = tattooForearmRightArray[0];
  let tattooForearmLeft = tattooForearmLeftArray[0];
  let tattooShoulderRight = tattooShoulderRightArray[0];
  let tattooShoulderLeft = tattooShoulderLeftArray[0];
  let tattooShinRight = tattooShinRightArray[0];
  let tattooShinLeft = tattooShinLeftArray[0];
  let tattooHead = tattooHeadArray[0];

  const changeHandler = (event) => {

    const key = event.target.id;
    stats[key] = event.target.value;
    setStats(stats)
  }

  const checkBoxHandler = (event) => {

    stats.WEIGHT_CLASSES = ["HW", "LHW", "MW", "WW", "LW"].filter(cl => document.querySelector("#" + cl).checked == true);
    setStats(stats);
    console.log(stats.WEIGHT_CLASSES);
  }

  const changeBody = (event) => {


    body = bodyArray[event.target.value];

    draw();

  }

  const changeSkin = (event) => {

    skin = skinArray[event.target.value]

    draw();

  }

  const changeFace = (event) => {

    face = faceArray[event.target.value]

    draw();

  }

  const changeShorts = (event) => {

    shorts = shortsArray[event.target.value]

    draw();

  }

  const changeHair = (event) => {

    hair = hairArray[event.target.value]

    draw();

  }

  const changeHairColor = (event) => {

    hairColor = hairColorArray[event.target.value]

    draw();

  }

  const changeBeard = (event) => {

    beard = beardArray[event.target.value]

    draw();

  }

  const changeBeardColor = (event) => {

    beardColor = beardColorArray[event.target.value]

    draw();

  }

  const changeMouth = (event) => {

    mouth = mouthArray[event.target.value]

    draw();

  }


  const changeTatooChest = (event) => {

    tattooChest = tattooChestArray[event.target.value]
    draw();

  }

  const changeTattooBicepRight = (event) => {

    tattooBicepRight = tattooBicepRightArray[event.target.value]
    draw();

  }

  const changeTattooForearmRight = (event) => {

    tattooForearmRight = tattooForearmRightArray[event.target.value]
    draw();

  }

  const changeTattooForearmLeft = (event) => {

    tattooForearmLeft = tattooForearmLeftArray[event.target.value]
    draw();

  }

  const changeTattooShoulderRight = (event) => {

    tattooShoulderRight = tattooShoulderRightArray[event.target.value]
    draw();

  }

  const changeTattooShoulderLeft = (event) => {

    tattooShoulderLeft = tattooShoulderLeftArray[event.target.value]
    draw();

  }

  const changeTattooShinRight = (event) => {

    tattooShinRight = tattooShinRightArray[event.target.value]
    draw();

  }

  const changeTattooShinLeft = (event) => {

    tattooShinLeft = tattooShinLeftArray[event.target.value]
    draw();

  }

  const changeTattooHead = (event) => {

    tattooHead = tattooHeadArray[event.target.value]
    draw();

  }

  const changeBodyHair = (event) => {

    bodyHair = bodyHairArray[event.target.value]
    draw();

  }


  const draw = () => {


    const bodyFiles = {
    body_file : `./assets/body_${body}_${skin}.png`,
    face_file : `./assets/face_${face}_${skin}.png`,
    mouth_file : `./assets/mouth_${mouth}_${skin}${face == "none"? "" : "_"+face}.png`,
    hair_file : `./assets/hair_${hair}_${hairColor}.png`,
    beard_file : `./assets/beard_${beard}_${beardColor}.png`,
    bodyHair_file : `./assets/body_hair_${bodyHair}_${body}.png`,
    shorts_file : `./assets/shorts_${shorts}_${body == "jacked" || body == "shredded" ? "skinny" : body}.png`,
    tattooChest_file : `./assets/tattoo_chest_${tattooChest}_${body}.png`,
    tattooBicepRight_file : `./assets/tattoo_bicep_right_${tattooBicepRight}.png`,
    tattooForearmRight_file : `./assets/tattoo_forearm_right_${tattooForearmRight}.png`,
    tattooForearmLeft_file : `./assets/tattoo_forearm_left_${tattooForearmLeft}.png`,
    tattooShoulderRight_file  : `./assets/tattoo_shoulder_right_${tattooShoulderRight}.png`,
    tattooShoulderLeft_file  : `./assets/tattoo_shoulder_left_${tattooShoulderLeft}.png`,
    tattooShinRight_file  : `./assets/tattoo_shin_right_${tattooShinRight}.png`,
    tattooShinLeft_file  : `./assets/tattoo_shin_left_${tattooShinLeft}.png`,
    tattooHead_file  : `./assets/tattoo_head_${tattooHead}.png`
    }

    console.log(JSON.stringify(bodyFiles))
    stats["IMG"] = JSON.stringify(bodyFiles)
    setStats(stats)
    
    const bodyParts = {
    bodyImg : new Image(),
    faceImg : new Image(),
    mouthImg : new Image(),
    bodyHairImg : new Image(),
    tattooChestImg : new Image(),
    tattooBicepRightImg : new Image(),
    tattooForearmRightImg : new Image(),
    tattooForearmLeftImg : new Image(),
  //  tattooShoulderRightImg : new Image(),
    tattooShoulderLeftImg : new Image(),
    tattooShinRightImg : new Image(),
    tattooShinLeftImg : new Image(),
    tattooHeadImg : new Image(),
    shortsImg : new Image(),
    hairImg : new Image(),
    beardImg : new Image()
    }

   
    let imgsLoaded = 0;
    const imgsArray = [];

    for (const part in bodyParts)
    {
    const file = bodyFiles[part.slice(0, -3) +"_file"]
    const img = bodyParts[part]

    if(!file.includes("none"))
    {
    img.src = file
    imgsArray.push(img)
    }
    else imgsLoaded++;

    img.onload = function () {
    
     // 
     imgsLoaded++;
     if(imgsLoaded >= 14)
     {
        clearCanvas();
       ctx.save();
       ctx.translate(-200,0);
       ctx.scale(-1,1)
     
      
        imgsArray.forEach(img=> ctx.drawImage(img, img.width * -1, 0))
        ctx.restore();
     
     }
    }
  }

  

  }

  const clearCanvas = () => {

    ctx.clearRect(0,0,canvas.width* 2,canvas.height * 2)
  }

  const getBody = (target) => {

   

    const obj = {};

    const clonedStats = {...stats}

    delete clonedStats._id;
    delete clonedStats.IMG; 
    delete clonedStats.type; 
    delete clonedStats.NAME;
    delete clonedStats.WEIGHT_CLASSES;
    delete clonedStats.owner;
    delete clonedStats.__typename;

    for (const stat in clonedStats)
    {
       
        console.log(stat)
        obj[stat] = parseFloat(document.querySelector("#"+stat).value);
        console.log(obj)

    }

    obj._id = stats._id;
    obj.NAME = stats.NAME;
    obj.IMG = stats.IMG;
    obj.WEIGHT_CLASSES = stats.WEIGHT_CLASSES;
  

    return obj

  }

  const submitFighter = (event) => {

  
    //if(!email || !isLoggedIn || isLoggedIn == undefined || myFighters.length > 0) return
    if(!email || !isLoggedIn || isLoggedIn == undefined) return

    if (event.target.getAttribute("data-locked") == true) return
    if (!verify(stats)) return

    event.target.setAttribute("data-locked", true)
    const body = getBody(event.target);

    fetch(`api/fighter/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(result =>  window.location = "/")
      .catch(err => reset(err, event))

  }

  const verify = (stats) => {

    const similarity = 0.8;
    const valid = /[a-zA-Z]/

    if (!stats.WEIGHT_CLASSES.length) { alert("You must select at least one weight class."); return false }
    else if (stats.NAME.length < 4 || valid.test(stats.NAME) == false) { alert("Invalid name"); return false }

    for(let n=0; n < myFighters.length; n++)
    {
      const _name = myFighters[n].NAME;

      console.log(myFighters[n],myFighters[n].NAME)

      console.log("similarity",stringSimilarity.compareTwoStrings(_name,stats.NAME))

      if(stringSimilarity.compareTwoStrings(_name,stats.NAME) >= 0.75) { alert("Name is too similar to another character that you've created. Please Enter a different name"); return false }

    }

    return true

  }

  const reset = (err, event) => {

    // event.target.setAttribute("data-locked", false)
    alert("Error " + err)

  }

  const doIncrement = (event) => {

    const strat = event.target.parentNode.querySelector(`[id^="STRAT"]`)
    strat.value <100 && strat.value++;
  }

  const doDecrement = (event) => {

    
    const strat = event.target.parentNode.querySelector(`[id^="STRAT"]`)
    strat.value > 0 && strat.value--;
  }

  const doIncrementApp = (event) => {

  

    const strat = event.target.parentNode.querySelector(`input`)
  
    strat.value > 0 && strat.value--;
   
   eval(strat.getAttribute("data-change")+"({target:{value:"+strat.value+"}})")


  }

  const doDecrementApp = (event) => {

   

    const strat = event.target.parentNode.querySelector(`input`)
  
    let max = event.target.parentNode.querySelector(`input`).getAttribute("max")

    strat.value < parseInt(max) && strat.value++;
    
  
   
    eval(strat.getAttribute("data-change")+"({target:{value:"+strat.value+"}})")
  

  
  }

  const doIncrementStat = (event) => {
    if(XP == 0 ) return
    const stat = event.target.parentNode.querySelector(`input`)
    if(stat.value <12) {
        stat.value = parseFloat(stat.value)+ .5;
        XP-= .5;
        document.querySelector("#XP").value = XP;
    }
  }

  const doDecrementStat = (event) => {

    
    const stat = event.target.parentNode.querySelector(`input`)
    const {id} = stat;
    if(stat.value > stats[id]) {
         stat.value = parseFloat(stat.value)- .5;
         XP += .5;
         document.querySelector("#XP").value = XP;

    }
  }

  const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    `;

  const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

  const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const H7 = styled.div`
    display:flex;
    justify-content:space-between;
    font-size:28px;
    margin-top:15px;
    `;

  const Div = styled.div`
    display:flex;
    font-weight:600;
    justify-content:space-between;
    text-shadow: 1px 1px #000;
    width: 100%;
    `;

  const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

    const IncrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:4px;
    padding-right:4px;
    `;

    const DecrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:6px;
    padding-right:4px;
    `;

  return (

   (myFighters.length <= 4 && ( <header className="App-header">
      <Wrapper>

        <H7 className="metallic">FIGHTER CREATOR</H7>
        <Container>
          <h3>Appearance</h3>
          <canvas id="appearanceCanvas" height={640} width={360}></canvas>
          <Div>
            <label htmlFor="BODY">Body</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="BODY" id="BODY" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeBody" onChange={changeBody}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="SKIN">Skin</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="SKIN" id="SKIN" type="Number" width="40px" defaultValue={0} min="0" max="1" style={{ marginLeft: "10px" }} data-change="changeSkin"  onChange={changeSkin}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="FACE">Face</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="FACE" id="FACE" type="Number" width="40px" defaultValue={0} min="0" max="6" style={{ marginLeft: "10px" }} data-change="changeFace"  onChange={changeFace}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="SHORTS">Shorts</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="SHORTS" id="SHORTS" type="Number" width="40px" defaultValue={0} min="0" max="22" style={{ marginLeft: "10px" }} data-change="changeShorts"  onChange={changeShorts}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="HAIR-COLOR">Hair Color</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="HAIR-COLOR" id="HAIR-COLOR" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeHairColor"  onChange={changeHairColor}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="HAIR">Hair</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="HAIR" id="HAIR" type="Number" width="40px" defaultValue={0} min="0" max="20" style={{ marginLeft: "10px" }} data-change="changeHair"  onChange={changeHair}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="BEARD-COLOR">Beard Color</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="BEARD-COLOR" id="BEARD-COLOR" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeBeardColor"  onChange={changeBeardColor}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="BEARD">Beard</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="BEARD" id="BEARD" type="Number" width="40px" defaultValue={0} min="0" max="6" style={{ marginLeft: "10px" }} data-change="changeBeard"  onChange={changeBeard}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="MOUTH">Mouth</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="MOUTH" id="MOUTH" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeMouth"  onChange={changeMouth}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="BODY-HAIR">Body Hair</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="BODY-HAIR" id="BODY-HAIR" type="Number" width="40px" defaultValue={0} min="0" max="1" style={{ marginLeft: "10px" }} data-change="changeBodyHair"  onChange={changeBodyHair}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-CHEST">Tattoo (Chest)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-CHEST" id="TATTOO-CHEST" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeTatooChest"  onChange={changeTatooChest}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-BICEP-RIGHT">Tattoo (Right Bicep)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-BICEP-RIGHT" id="TATTOO-BICEP-RIGHT" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeTattooBicepRight"  onChange={changeTattooBicepRight}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-FOREARM-RIGHT">Tattoo (Right Forearm)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-FOREARM-RIGHT" id="TATTOO-FOREARM-RIGHT" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeTattooForearmRight"  onChange={changeTattooForearmRight}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-FOREARM-LEFT">Tattoo (Left Forearm)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-FOREARM-LEFT" id="TATTOO-FOREARM-LEFT" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeTattooForearmLeft"  onChange={changeTattooForearmLeft}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-SHOULDER-LEFT">Tattoo (Left Shoulder)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-SHOULDER-LEFT" id="TATTOO-SHOULDER-LEFT" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeTattooShoulderLeft"  onChange={changeTattooShoulderLeft}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
         </Div>
          <Div>
            <label htmlFor="TATTOO-SHIN-LEFT">Tattoo (Left Shin)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-SHIN-LEFT" id="TATTOO-SHIN-LEFT" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeTattooShinLeft"  onChange={changeTattooShinLeft}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <Div>
            <label htmlFor="TATTOO-SHIN-RIGHT">Tattoo (Right Shin)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-SHIN-RIGHT" id="TATTOO-SHIN-RIGHT" type="Number" width="40px" defaultValue={0} min="0" max="2" style={{ marginLeft: "10px" }} data-change="changeTattooShinRight"  onChange={changeTattooShinRight}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
         </Div>
          <Div>
            <label htmlFor="TATTOO-HEAD">Tattoo (Head)</label>
            <div>
            <IncrBtn onClick={doIncrementApp}>&#8592;</IncrBtn>
            <input name="TATTOO-HEAD" id="TATTOO-HEAD" type="Number" width="40px" defaultValue={0} min="0" max="3" style={{ marginLeft: "10px" }} data-change="changeTattooHead"  onChange={changeTattooHead}></input>
            <DecrBtn onClick={doDecrementApp}>&#8594;</DecrBtn>
            </div>
          </Div>
          <label htmlFor="NAME"><h3>Name</h3></label>
          <input name="NAME" id="NAME" type="text" defaultValue={stats.NAME} onChange={changeHandler}></input>
          <h3>Weight Class</h3>
          <Div>
            Heavyweight <input type="checkbox" id="HW" name="HW" onChange={checkBoxHandler} />
          </Div>
          <Div>
            Light-Heavyweight <input type="checkbox" id="LHW" name="LHW" onChange={checkBoxHandler} />
          </Div>
          <Div>
            Middleweight <input type="checkbox" id="MW" name="MW" onChange={checkBoxHandler} />
          </Div>
          <Div>
            Welterweight <input type="checkbox" id="WW" name="WW" onChange={checkBoxHandler} />
          </Div>
          <Div>
            Lightweight <input type="checkbox" id="LW" name="LW" onChange={checkBoxHandler} />
          </Div>

          <h3>STATS</h3>

          <Div>
            <label htmlFor="XP">XP to Spend:</label>  
            <input className="stat-class" name="XP" id="XP" type="Number" width="40px"  readOnly={true} value={stats.XP}  min="3" max="10" style={{ marginLeft: "10px",marginBottom:"15px" }}></input>      
        </Div>

        <h3>Physical</h3>

          <Div>
            <label htmlFor="STRENGTH">Strength</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="STRENGTH" id="STRENGTH" type="Number" width="40px" defaultValue={stats.STRENGTH}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="KO_POWER">KO Power</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="KO_POWER" id="KO_POWER" type="Number" width="40px" defaultValue={stats.KO_POWER}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CONDITIONING">Conditioning</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="CONDITIONING" id="CONDITIONING" type="Number" width="40px" defaultValue={stats.CONDITIONING}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CHIN">Chin</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="CHIN" id="CHIN" type="Number" width="40px" defaultValue={stats.CHIN} min="3" max="10"  step="0.5" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>Mental</h3>

          <Div>
            <label htmlFor="AGGRESIVENESS">Aggressiveness</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="AGGRESIVENESS" id="AGGRESIVENESS" type="Number" width="20px" defaultValue={stats.AGGRESIVENESS}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="KILLER_INSTINCT">Killer Instinct</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="KILLER_INSTINCT" id="KILLER_INSTINCT" type="Number" width="20px" defaultValue={stats.KILLER_INSTINCT} step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="ECCENTRICITY">Eccentricity</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="ECCENTRICITY" id="ECCENTRICITY" type="Number" width="20px" defaultValue={stats.ECCENTRICITY}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>STANDING</h3>
          <Div>
            <label htmlFor="KICKS">Kicks</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="KICKS" id="KICKS" type="Number" width="40px" defaultValue={stats.KICKS}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="PUNCHES">Punches</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="PUNCHES" id="PUNCHES" type="Number" width="40px" defaultValue={stats.PUNCHES}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CLINCH">Clinch</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="CLINCH" id="CLINCH" type="Number" width="40px" defaultValue={stats.CLINCH} step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="TAKEDOWNS">Takedowns</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="TAKEDOWNS" id="TAKEDOWNS" type="Number" width="20px" defaultValue={stats.TAKEDOWNS} step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
         </Div>

          <Div>
            <label htmlFor="SPRAWL">Sprawl</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="SPRAWL" id="SPRAWL" type="Number" width="20px" defaultValue={stats.SPRAWL}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>GROUND</h3>

          <Div>
            <label htmlFor="GROUND_AND_POUND">Ground and Pound</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="GROUND_AND_POUND" id="GROUND_AND_POUND" type="Number" width="20px" defaultValue={stats.GROUND_AND_POUND}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="SUBMISSIONS">Submission</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="SUBMISSIONS" id="SUBMISSIONS" type="Number" width="20px" defaultValue={stats.SUBMISSIONS}  step="0.5" min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="GUARD">Guard</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="GUARD" id="GUARD" type="Number" width="20px" defaultValue={stats.GUARD} min="3" max="10"   step="0.5" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="SCRAMBLES">Scrambles</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input name="SCRAMBLES" id="SCRAMBLES" type="Number" width="20px" defaultValue={stats.SCRAMBLES} min="3" max="10"  step="0.5" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>STRATEGY</h3>
          <h4>Standing </h4>

          <Div>
            <label htmlFor="STRAT1_STRIKING">Striking</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT1_STRIKING" id="STRAT1_STRIKING" type="Number" width="20px" defaultValue={stats.STRAT1_STRIKING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT1_GRAPPLING">Grappling</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT1_GRAPPLING" id="STRAT1_GRAPPLING" type="Number" width="20px" defaultValue={stats.STRAT1_GRAPPLING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Grappling</h4>

          <Div>
            <label htmlFor="STRAT2_CLINCH">Clinch</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT2_CLINCH" id="STRAT2_CLINCH" type="Number" width="20px" defaultValue={stats.STRAT2_CLINCH} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT2_SHOOT">Takedowns</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT2_SHOOT" id="STRAT2_SHOOT" type="Number" width="20px" defaultValue={stats.STRAT2_SHOOT} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Ground: Top</h4>

          <Div>
            <label htmlFor="STRAT3_GROUND_AND_POUND">Ground and Pound</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_GROUND_AND_POUND" id="STRAT3_GROUND_AND_POUND" type="Number" width="20px" defaultValue={stats.STRAT3_GROUND_AND_POUND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_POSITIONING">Positioning</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_POSITIONING" id="STRAT3_POSITIONING" type="Number" width="20px" defaultValue={stats.STRAT3_POSITIONING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_SUBMISSION">Submissions</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_SUBMISSION" id="STRAT3_SUBMISSION" type="Number" width="20px" defaultValue={stats.STRAT3_SUBMISSION} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_STAND">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_STAND" id="STRAT3_STAND" type="Number" width="20px" defaultValue={stats.STRAT3_STAND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Ground: Bottom</h4>

          <Div>
            <label htmlFor="STRAT4_POSITIONING">Positioning</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_POSITIONING" id="STRAT4_POSITIONING" type="Number" width="20px" defaultValue={stats.STRAT4_POSITIONING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT4_SUBMISSION">Submissions</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_SUBMISSION" id="STRAT4_SUBMISSION" type="Number" width="20px" defaultValue={stats.STRAT4_SUBMISSION} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT4_SCRAMBLING">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_SCRAMBLING" id="STRAT4_SCRAMBLING" type="Number" width="20px" defaultValue={stats.STRAT4_SCRAMBLING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Striking</h4>

          <Div>
            <label htmlFor="STRAT5_PUNCHING">Punching</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT5_PUNCHING" id="STRAT5_PUNCHING" type="Number" width="20px" defaultValue={stats.STRAT5_PUNCHING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT5_KICKING">Kicking</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT5_KICKING" id="STRAT5_KICKING" type="Number" width="20px" defaultValue={stats.STRAT5_KICKING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>If Takedown Fails:</h4>


          <Div>
            <label htmlFor="STRAT6_PULL_GUARD">Pull Guard</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT6_PULL_GUARD" id="STRAT6_PULL_GUARD" type="Number" width="20px" defaultValue={stats.STRAT6_PULL_GUARD} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT6_STAND">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT6_STAND" id="STRAT6_STAND" type="Number" width="20px" defaultValue={stats.STRAT6_STAND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>


        </Container>

        <ViewBtn className="button-platinum" id="submit-btn" data-locked={false} onClick={submitFighter}>Submit</ViewBtn>

      </Wrapper>
    </header>))
  )
}

export default Creator;