import { useReducer } from 'react';
import { Howl, Howler } from 'howler';


import {
    UPDATE_FIGHTERS,
    UPDATE_FIGHTER1,
    UPDATE_FIGHTER2,
    UPDATE_CLASS,
    UPDATE_LOGIN,
    UPDATE_FIGHTS,
    UPDATE_CARDS,
    UPDATE_CARD_TITLE,
    UPDATE_CARD_SIZE,
    UPDATE_EMAIL,
    UPDATE_NAME,
    UPDATE_MY_FIGHTERS,
    UPDATE_CURRENT_FIGHTER,
    UPDATE_WINNERS,
    UPDATE_LAST_CARD,
    UPDATE_CARD_STARTED
    
} from './actions';

import {
     sounds
    
} from './sounds';



const initial = {
    startDate: "03/23/2023",
    fighters: [],
    fighter1: localStorage.getItem('fighter1'),
    fighter2: localStorage.getItem('fighter2'),
    rounds: localStorage.getItem('rounds'),
    sims: localStorage.getItem('sims'),
    current_class:"HW",
    isLoggedIn: undefined,
    cards:[],
    fights:{},
    title: null,
    cardSize: null,
    email: null,
    name: null,
    myFighters: [],
    currentFighter: null,
    winners:[],
    lastCard:[],
    cardStarted:false,
    stadium : new Howl({
        src: ['/sounds/stadium.mp3'],
        loop: true
      }),
    sounds: sounds

  };



export const reducer = (state=initial, action) => {
    switch (action.type) {

        case UPDATE_CLASS:
            return {
                ...state,
                current_class: action.current_class
            }
        case UPDATE_FIGHTERS:
            return {
                ...state,
                fighters: [...action.fighters]
            };
        case UPDATE_FIGHTER1:
                return {
                    ...state,
                    fighter1: action.fighter1
            };
        case UPDATE_FIGHTER2:
                return {
                    ...state,
                    fighter2: action.fighter2
            };
        case UPDATE_LOGIN:
                return {
                    ...state,
                    isLoggedIn: action.isLoggedIn
            };
        case UPDATE_FIGHTS:
                return {
                    ...state,
                    fights: action.fights
            };
        case UPDATE_CARDS:
                return {
                    ...state,
                    cards: action.cards
            };          
        case UPDATE_CARD_TITLE:
                    return {
                        ...state,
                        title: action.title
                };
        case UPDATE_CARD_SIZE:
                    return {
                        ...state,
                        cardSize: action.cardSize
                };
        case UPDATE_EMAIL:
                    return {
                        ...state,
                        email: action.email
                };
        case UPDATE_NAME:
                    return {
                        ...state,
                        name: action.name
                };
        case UPDATE_MY_FIGHTERS:
                    return {
                        ...state,
                        myFighters: [...action.myFighters]
                };
        case UPDATE_CURRENT_FIGHTER:
                    return {
                        ...state,
                        currentFighter: action.currentFighter
                };
        case UPDATE_CURRENT_FIGHTER:
                    return {
                        ...state,
                        currentFighter: action.currentFighter
                };
        case UPDATE_WINNERS:
                    return {
                        ...state,
                        winners: action.winners
                };
        case UPDATE_LAST_CARD:
                    return {
                        ...state,
                        lastCard: [...action.lastCard]
                };
        case UPDATE_CARD_STARTED:
                    return {
                        ...state,
                        cardStarted: action.cardStarted
                };
     
     
 
     
 
 

        default:
            return state;
    }
};

export default reducer;



