import styled, { css } from 'styled-components';
import { QUERY_FIGHTERS ,QUERY_MY_FIGHTERS} from "../utils/queries";
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FIGHTERS, UPDATE_CLASS } from '../utils/actions';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';


// This files contains content and UI logic for main part of the UI showing the product and the details

function Selector() {

    const state = useSelector((state) => {
        return state
    });
    const dispatch = useDispatch();

    let { fighter1, fighter2, rounds, sims, current_class,myFighters} = state;
    let selectedColor = "";

   

    const {  data:data } = useQuery(QUERY_FIGHTERS);

    const {data:fighter_data} = useQuery(QUERY_MY_FIGHTERS , {
        variables: { skip:myFighters==null||myFighters == undefined||myFighters.length==0 ? true : false,fighters:myFighters||[]},
      }) 


    const [myFightersObjects,setMyFighters] = useState([])


    useEffect(() => {
        if (data) {
            dispatch({
                type: UPDATE_FIGHTERS,
                fighters: data.fighters
            });

            state.fighter1 = null;
            state.fighter2 = null;

        }

        if (fighter_data)
        {
           console.log(fighter_data)
          const { myFighters } = fighter_data;

          setMyFighters(myFighters)

         // console.log("inactive fighters",data.fighters.filter(f=>f.type == "inactive"))
         

        }

    }, [data, fighter_data, dispatch]);

    function gotoFight(event) {

        if (fighter1 && fighter2 && rounds && sims) window.location = "/sim";

    }

    function setSims(event) {
        if (event.target.id == "one") localStorage.setItem("sims", 1);
        if (event.target.id == "hundred") localStorage.setItem("sims", 100);

        sims = localStorage.getItem("sims");


        changeColor(event.target.id, event.target.id == "one" ? "hundred" : "one");
    }

    function setRounds(event) {
        if (event.target.id == "three") localStorage.setItem("rounds", 3);
        if (event.target.id == "five") localStorage.setItem("rounds", 5);

        rounds = localStorage.getItem("rounds");

        changeColor(event.target.id, event.target.id == "three" ? "five" : "three");
    }

    function setWeightClass(event) {

        console.log(event.target.value)

        dispatch({
            type: UPDATE_CLASS,
            current_class: event.target.value
        });
    }

    function changeColor(selected, unselected) {

        selectedColor = document.getElementById(selected).style.color;
        document.getElementById(selected).style.color = "#fff";
        document.getElementById(selected).style.textShadow = "0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black";
        //document.getElementById(selected).style.backgroundColor = "#000";

        document.getElementById(unselected).style.color = selectedColor;
        document.getElementById(unselected).style.textShadow = "0 0 1px white";
        
        //document.getElementById(unselected).style.backgroundColor = "#00A598";

    }


    function filterFighters() {


        let fighters = state.fighters;


        return fighters
    }

    const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin-top:20px;
    `;

    const Text = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    padding:5px 15px 5px 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    text-align: center;
    text-style:bold;
    font-size:16px;
    background-color: rgba(0,0,0,0.5);
    `;

    const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    height: 25px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 8px;
    margin-right: 8px;
    display:flex;
    align-items:center;
    justify-content: center;
    padding-top:5px;
    padding-bottom:5px;
    white-space: nowrap;
    `;

    const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    white-space: nowrap;
    `;

    return (

        <header className="App-header">
            <Wrapper>
               
                <ViewBtn id="my-classes-div" className="metallic" style={{ display: myFightersObjects.length > 0 ? "" : "none",border:"1px solid #fff"}}>
                My Fighters
                </ViewBtn>
                
                <Container>
                {myFightersObjects.length > 0 && (
                <div>
                {myFightersObjects.map(fighter => (
                <FighterBtn _id={fighter._id.toString()} key={fighter._id} NAME={fighter.NAME.toUpperCase()} column={1} style={{minWidth:"150px"}}/>
                ) )}
                </div>
                )} 
                </Container>  


                <Select className="metallic" defaultValue={current_class} id="weight-classes-div" onChange={setWeightClass}>
                    <option value="HW">Heavyweights</option>
                    <option value="LHW">Light-Heavyweights</option>
                    <option value="MW">Middleweights</option>
                    <option value="WW">Welterweights</option>
                    <option value="LW">Lightweights</option>
                    <option value="FW">Featherweights</option>
                    <option value="BW">Bantamweights</option>
                </Select>
                <Container>
                 <Text className="button-pinkgold">Select Fighter 1</Text>
                 <Text className="button-pinkgold">Select Fighter 2</Text>
                </Container>
                <Container>
                    <div>
                        {filterFighters().filter(fighter => fighter.WEIGHT_CLASSES.includes(current_class)).map(fighter => (
                            <FighterBtn _id={fighter._id } key={fighter._id+1} NAME={fighter.NAME} column={1} />

                        ))}
                    </div>
                    <div>
                        {filterFighters().filter(fighter => fighter.WEIGHT_CLASSES.includes(current_class)).map(fighter => (
                            <FighterBtn _id={fighter._id } key={fighter._id+2} NAME={fighter.NAME} column={2} />
                        ))}
                    </div>

                </Container>
              
                <Container>
                    <ViewBtn className="button-whitegold" id="one" onClick={setSims}>One Simulation</ViewBtn>
                    <ViewBtn className="button-whitegold" id="hundred" onClick={setSims}>100 Simulations</ViewBtn>
                 </Container>
                 <Text>
                Select the number of simulations you wish to run.
                </Text>
              
                <Container>
                    <ViewBtn className="button-whitegold" id="three" onClick={setRounds}>3 Rounds</ViewBtn>
                    <ViewBtn className="button-whitegold" id="five" onClick={setRounds}>5 Rounds</ViewBtn>
                </Container>
                <Text>
                Select the number of rounds, and finally click simulate to view the fight.
                </Text>
                <ViewBtn  className="button-whitegold" onClick={gotoFight}>Simulate</ViewBtn>

            </Wrapper>
        </header>
    )
}

export default Selector;