import ReactDOM from 'react-dom'
import { QUERY_FIGHTERS, QUERY_ONE_FIGHT, QUERY_MY_XP } from "../utils/queries";
import { useQuery } from '@apollo/react-hooks';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useBeforeunload, Beforeunload } from 'react-beforeunload';
import AdSense from 'react-adsense';


function Sim(props) {

  const ObjectId = require('mongoose').Types.ObjectId;
  String.prototype.toObjectId = function () {
    if (this == "") return null
    else return new ObjectId(this.toString())
  };


  const state = useSelector((state) => {
    return state
  });



  const { fighter1, fighter2, rounds, sims, stadium, sounds } = state;

  const paramId = props.location.search.split("&")[0]?.split("=")[1] || null;
  const fighter1Id = props.location.search.split("&")[1]?.split("=")[1] || fighter1;
  const fighter2Id = props.location.search.split("&")[2]?.split("=")[1] || fighter2;
  const myFighters = state.myFighters;



  const isMyFighter = () => {


    for (let i = 0; i < myFighters.length; i++) {
      console.log(myFighters[i], fighter1Id, myFighters[i] == fighter1Id)

      if (fighter1Id == myFighters[i].toString()) return fighter1Id
      if (fighter2Id == myFighters[i].toString()) return fighter2Id
    }

    return null
  }


  const { data: fight_data } = useQuery(QUERY_ONE_FIGHT, {
    variables: { skip: paramId === null, _id: paramId || "".toObjectId() },
  })

  const { data: fighter_data } = useQuery(QUERY_FIGHTERS);


  if ((fighter1 == "null" || fighter2 == "null") && !paramId) {

    window.location = "/";

  }

  let _f1 = fighter1;
  let _f2 = fighter2;


  const [_f1name, setF1Name] = useState("")
  const [_f2name, setF2Name] = useState("")
  const [ROUNDS, setRounds] = useState([])
  const [PBP, setPBP] = useState([]);

  let action = 0;
  let canvas, ctx, octagon, fighterImg1, fighterImg2, fighters, xpGained;
  let called = false;
  let delay = false;
  let slowTimeout = null;
  let fastTimeout = null;



  const leftModifier = window.innerWidth < 1000 ? 0.8 : 1.1;
  const rightModifier = window.innerWidth < 1000 ? -1.4 : -1.2;


  console.log(myFighters)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {


    canvas = document.querySelector("#scene-div")
    ctx = canvas.getContext("2d");
    loadArena();

    resetFighters();

    if (fighter_data) {
      fighters = fighter_data.fighters;
      loadFighter1()
      loadFighter2()
    }


  }, [fighter_data]);

  useEffect(() => {


    console.log(fight_data)


    if (!paramId) {
      loadSimFight();

    }
    else if (fight_data) {

      loadGameFight();

    }


  }, [fight_data]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";

      console.log("unmount", stadium);
      if (stadium) stadium.stop()
    };
  }, []);


  const loadGameFight = async () => {




    const { ACTION } = fight_data.fight;

    console.log("fight is", JSON.parse(ACTION));

    console.log("sims", sims)

    document.getElementById("next-btn").style.display = 'flex';

    parsePBP(JSON.parse(ACTION))



  }


  const loadSimFight = async () => {
    const response = await fetch(`api/sim/fighter1/${_f1}/fighter2/${_f2}/sims/${sims}/rounds/${rounds}`);
    const result = await response.json()

    if (sims == 1) {

      document.getElementById("next-btn").style.display = 'flex';

      parsePBP(result)


      return
    }
    else {

      document.getElementById("result-div").innerHTML = parseResult(result);
      document.getElementById("stand-btn1").addEventListener('click', expand);
      document.getElementById("gnp-btn1").addEventListener('click', expand);
      document.getElementById("sub-btn1").addEventListener('click', expand);
      document.getElementById("jd-btn1").addEventListener('click', expand);
      document.getElementById("stand-btn2").addEventListener('click', expand);
      document.getElementById("gnp-btn2").addEventListener('click', expand);
      document.getElementById("sub-btn2").addEventListener('click', expand);
      document.getElementById("jd-btn2").addEventListener('click', expand);

    }



  }

  const loadXP = async () => {


    if (!isMyFighter()) return

    const response = await fetch(`api/xp/${isMyFighter()}`);
    console.log("load xp", response)

    const result = await response.json()

    console.log("load xp", result)

    if (!result || result == undefined) return


    xpGained = result;

  }

  const expand = function (event) {

    console.log(event.target.id.split("btn").join("breakdown"))
    const newId = event.target.id.split("btn").join("breakdown");
    const div = document.getElementById(newId);
    div.style.display == "none" ? div.style.display = "" : div.style.display = "none";
    event.target.textContent == "+" ? event.target.textContent = "-" : event.target.textContent = "+";

  }

  const parsePBP = res => {
    console.log(res)

    const PBP = [];
    const ROUNDS = [];

    for (let p in res) {
      const _action = res[p];
      PBP.push(_action);
      ROUNDS.push(p.split("-")[0])

    }

    setPBP(PBP)
    setRounds(ROUNDS)

  }

  const loadFighter1 = () => {

    if (fighterImg1) return

    const f1 = fighters.find(e => e._id.toString() == fighter1Id);
    fighterImg1 = JSON.parse(f1.IMG)

    draw(fighterImg1, "right")



    setF1Name(f1.NAME);


  }

  const loadFighter2 = () => {

    if (fighterImg2) return

    const f2 = fighters.find(e => e._id.toString() == fighter2Id);
    fighterImg2 = JSON.parse(f2.IMG)

    draw(fighterImg2, "left")
    setF2Name(f2.NAME);


    var metal = document.querySelectorAll(".metallic");
    metal.forEach(node => node.setAttribute("data-heading", node.textContent));




  }

  const loadArena = () => {


    if (octagon) return

    octagon = new Image()
    octagon.src = "/assets/octagon.jpeg"

    octagon.onload = () => {

      ctx.save();
      ctx.scale(2.5, 2.5)
      // ctx.drawImage(octagon,-100,-75)
      //ctx.drawImage(video,0,0,canvas.width,canvas.height)
      ctx.restore()
    }


  }

  const draw = (bodyFiles, position) => {



    const bodyParts = {
      bodyImg: new Image(),
      faceImg: new Image(),
      mouthImg: new Image(),
      bodyHairImg: new Image(),
      tattooChestImg: new Image(),
      tattooBicepRightImg: new Image(),
      tattooForearmRightImg: new Image(),
      tattooForearmLeftImg: new Image(),
      //  tattooShoulderRightImg : new Image(),
      tattooShoulderLeftImg: new Image(),
      tattooShinRightImg: new Image(),
      tattooShinLeftImg: new Image(),
      tattooHeadImg: new Image(),
      shortsImg: new Image(),
      hairImg: new Image(),
      beardImg: new Image()
    }


    let imgsLoaded = 0;
    const imgsArray = [];

    for (const part in bodyParts) {
      const file = bodyFiles[part.slice(0, -3) + "_file"]
      const img = bodyParts[part]

      if (!file.includes("none")) {
        img.src = file
        imgsArray.push(img)
      }
      else imgsLoaded++;

      img.onload = function () {

        // 
        imgsLoaded++;
        if (imgsLoaded >= 14) {

          let X;

          if (position == "right") {
            ctx.save();
            ctx.scale(-0.5, 0.5);
            X = img.width * rightModifier;

          }
          else {
            ctx.save();
            ctx.scale(0.5, 0.5);
            X = img.width * leftModifier;
          }


          imgsArray.forEach(img => ctx.drawImage(img, X, 200))
          ctx.restore();



        }
      }
    }



  }


  const parseResult = res => {

    return `<div style='${Fdiv}'>
    <ul style='${Ul}'>
    <li><h4>${res[_f1].NAME}</h4></li>
    <li><h4>TOTAL WINS: ${res[_f1].WIN}</h4></li>
    </ul>
    <ul style='${Ul}'>METHOD
    <li><span id='stand-btn1' style='${btn}'>+</span>  STRIKES: ${res[_f1].STANDING.length}<br><span id='stand-breakdown1' style=${Breakdown}>${parseBreakdown(res[_f1].STANDING)}</span></li>
    <li><span id='sub-btn1' style='${btn}'>+</span>  SUBMISSIONS: ${res[_f1].SUBMISSION.length}<br><span id='sub-breakdown1' style=${Breakdown}>${parseBreakdown(res[_f1].SUBMISSION)}</span></li>
    <li><span id='gnp-btn1' style='${btn}'>+</span>  GROUND STRIKES: ${res[_f1].GROUND_STRIKES.length}<br><span id='gnp-breakdown1' style=${Breakdown}>${parseBreakdown(res[_f1].GROUND_STRIKES)}</span></li>
    <li><span id='jd-btn1' style='${btn}'>+</span>  DECISION: ${res[_f1].JD.length}<br><span id='jd-breakdown1' style=${Breakdown}>${parseBreakdown(res[_f1].JD)}</span></li>
    </ul>
    </div>



    <div style='${Rdiv}'>
    <ul style='${Ul}'>
    <li><h4>${res[_f2].NAME}</h4></li>
    <li><h4>TOTAL WINS: ${res[_f2].WIN}</h4></li>
    </ul>
    <ul style='${Ul}'>METHOD
    <li><span id='stand-btn2' style='${btn}'>+</span>  STRIKES: ${res[_f2].STANDING.length}<br><span id='stand-breakdown2' style=${Breakdown}>${parseBreakdown(res[_f2].STANDING)}</span></li>
    <li><span id='sub-btn2' style='${btn}'>+</span>  SUBMISSIONS: ${res[_f2].SUBMISSION.length}<br><span id='sub-breakdown2' style=${Breakdown}>${parseBreakdown(res[_f2].SUBMISSION)}</span></li>
    <li><span id='gnp-btn2' style='${btn}'>+</span>  GROUND STRIKES: ${res[_f2].GROUND_STRIKES.length}<br><span id='gnp-breakdown2' style=${Breakdown}>${parseBreakdown(res[_f2].GROUND_STRIKES)}</span></li>
    <li><span id='jd-btn2' style='${btn}'>+</span>  DECISION: ${res[_f2].JD.length}<br><span id='jd-breakdown2' style=${Breakdown}>${parseBreakdown(res[_f2].JD)}</span></li>
    </ul>
    </div>

  `

  }




  const parseBreakdown = method => {

    const allMoves = {};

    method.forEach(m => {
      if (m in allMoves) allMoves[m] += 1;
      else allMoves[m] = 1;

    });

    const _allMoves = Object.entries(allMoves)

    _allMoves.sort((a, b) => b[1] - a[1])

    let ul = ``;

    for (let m = 0; m < _allMoves.length; m++) {
      ul += `${_allMoves[m][0]} : ${_allMoves[m][1]}</br>`
    }

    return ul


  }

  function fightStarted(event)
  {
   if(event) event.target.textContent = "Next Move";
  }

  function handleAutoPlay(event) {


    if (slowTimeout) clearInterval(slowTimeout);
    if (fastTimeout) clearInterval(fastTimeout);

    if (event.target.value == "slow") slowTimeout = setInterval(() => nextMove(null), 2500)
    if (event.target.value == "fast") fastTimeout = setInterval(() => nextMove(null), 1250)

    playVideoAndAudio()
  }

  function nextMove(event) {

   

    if (action == 0) { loadXP(); stadium.play(); fightStarted(event)} // playStadium(); }
    if (delay) return
    if (action > PBP.length) return

    const ACTION = PBP[action];

    action++;

    if (ACTION == undefined) return
    if (ACTION.length <= 5) return nextMove(null)
    if (action == PBP.length) endSim();


    getRound(action);
    playVideoAndAudio();

    document.getElementById("turn-div").innerHTML = "";
    document.getElementById("clock-div").textContent = ACTION[0];


    if (ACTION[1] && ACTION[2] && ACTION[3] && ACTION[4]) {
      const F2CARDIO = ACTION[1].includes(_f2name) ? ACTION[1].split(`cardio `)[1] : ACTION[3].split(`cardio `)[1];
      const F2HP = ACTION[2].includes(_f2name) ? ACTION[2].split(`HP `)[1] : ACTION[4].split(`HP `)[1];
      const F1CARDIO = ACTION[1].includes(_f1name) ? ACTION[1].split(`cardio `)[1] : ACTION[3].split(`cardio `)[1];
      const F1HP = ACTION[2].includes(_f1name) ? ACTION[2].split(`HP `)[1] : ACTION[4].split(`HP `)[1];

      // console.log(ACTION[1], ACTION[2], ACTION[3], ACTION[4])

      document.getElementById("f1hp-div").style.width = F1HP;
      document.getElementById("f1cardio-div").style.width = F1CARDIO;
      document.getElementById("f2hp-div").style.width = F2HP;
      document.getElementById("f2cardio-div").style.width = F2CARDIO;

      changeColor()

    }


    for (let i = 5; i < ACTION.length; i++) {

      let bubble = ACTION[i].includes(_f1name) ? "action-left" : "action";

      if (ACTION[i].includes(_f2name)) bubble = "action-right";
      if (ACTION[i].includes(_f2name) && ACTION[i].includes(_f1name)) bubble = "action";



      delay = true;

      setTimeout(() => {
        document.getElementById("turn-div").innerHTML += `<p class='${bubble}'>${ACTION[i]}</p>`;
        delay = false;
      }, 200 + 1);

    }

    getAudio([...ACTION.slice(5)])
    
  }

  function changeColor() {
    const button = document.getElementById("next-btn");

    // Change the font color and border color to green
    button.style.color = "green";
    button.style.borderColor = "green";

    // Wait for 1 second (1000 milliseconds)
    setTimeout(function () {
      // Revert back to the initial colors
      button.style.color = "";
      button.style.borderColor = "";
    }, 250);
  }

  function playVideoAndAudio() {
    document.getElementById("video-div").play()

  }

  function getRound(a) {

    const round = ROUNDS[a] > rounds ? rounds : ROUNDS[a];

    if (!round) return


    let roundTxt = round;

    if (round == 1) roundTxt += "st";
    if (round == 2) roundTxt += "nd";
    if (round == 3) roundTxt += "rd";
    if (round == 4) roundTxt += "th";
    if (round == 5) roundTxt += "th";

    document.getElementById("round-div").textContent = roundTxt;
  }

  const resetFighters = () => {
    localStorage.setItem('fighter1', null);
    localStorage.setItem('fighter2', null);
  }

  const endSim = () => {
    document.getElementById("next-btn").style.display = "none";
    document.getElementById("end-btn").style.display = "flex";

    setTimeout(addXPDiv, 500)

  }

  const addXPDiv = async () => {

    if (!xpGained || xpGained == undefined) return;
    console.log(xpGained)



    fetch("api/xp/update/" + xpGained._id)
      .then(res => {

        const pbpDiv = document.getElementById("turn-div")
        const wrap = document.createElement("div")
        const starDiv = document.createElement("div")

        wrap.style.width = "100%";
        wrap.style.display = "flex"
        wrap.style.justifyContent = "center"
        starDiv.id = "badge-star"
        starDiv.innerHTML = "GAINED    " + xpGained.XP + " XP";

        wrap.appendChild(starDiv)
        pbpDiv.appendChild(wrap)
      })
      .catch(error => console.log(error))

  }

  const gotoFighterSelect = () => {
    if (!paramId) window.location = "/";
    else window.location = "/card";
  }

  const getAudio = (pbp) => {


    const PUNCHES = ["Jab", "Straight Cross", "Body shot", "Lead hook", "Hook", "Overhand Right"];
    const CLINCH_PUNCHES = ["Body shot", "Snap elbow", "Short Uppercut"];
    const SINGLE_COLLAR_PUNCHES = ["Short Hook to the face", "Snap elbow", "Uppercut"];
    const FLASH_PUNCHES = ["Spinning backfist", "Spinning back-elbow", "Superman Punch"];

    const KICKS = ["Low kick", "Calf Kick", "Front kick to the body", "Middle kick to the body", "High kick to the Head", "Calf Kick", "Low Kick", "Middle Kick to the Body"];
    const FLASH_KICKS = ["Spinning kick to the body", "Front Kick to the Face", "Wheel Kick"];

    const CLINCH_KICKS = ["Foot Stomp", "Knee to the leg", "Knee to the body"];
    const DOUBLE_COLLAR_KICKS = ["Knee to the Body", "Knee to the head", "Knee to the Face"];

    const TAKEDOWNS = ["Single-leg takedown", "Double-leg takedown", "Double-Leg Takedown", "Waistlock takedown", "Leg Trip takedown", "Hip Toss Takedown"];
    const SLAMS = ["Hip-Toss Slam", "High Crotch Slam", "Lateral Drop", "Double-leg Slam", "Suplex"];

    const ALL_TAKEDOWNS = TAKEDOWNS.concat(SLAMS);
    const ALL_PUNCHES = PUNCHES.concat(CLINCH_PUNCHES).concat(SINGLE_COLLAR_PUNCHES).concat(FLASH_PUNCHES);
    const ALL_KICKS = KICKS.concat(FLASH_KICKS);
    const ALL_KNEES = CLINCH_KICKS.concat(DOUBLE_COLLAR_KICKS);
    const ALL_GNP = ["Series of punches", "Series of hammerfists", "Series of elbows to the face", "Series of knees", "Series of elbows to the body", "Series of punches to the face", "Series of punches to the head", "Series of elbows to the head"];


    const GNP_PUNCH = ["Punch to the body", "Elbow to the body", "Punch to the face", "Elbow to the face", "Elbow to the body", "Punch to the face",
      "Punch to the Face", "Elbow to the Face", "Punch to the head", "Elbow to the head"];

    const IS_HIT = ["shrugs", "Partially", "Solid", "Vicious", "Crushing"];

    const DEFENSE_PUNCHES = ["slips", "ducks", "sidesteps", "hops out of range"];
    const DEFENSE_KICKS = ["checks", "sidesteps", "ducks"];



    let action = null;
    let reaction = null;

    for (let i = 0; i < pbp.length; i++) {
      const move = pbp[i];

      for (let j = 0; j < ALL_PUNCHES.length; j++) {
        if (move.includes(ALL_PUNCHES[j])) action = "PUNCH";
        continue
      }
      for (let j = 0; j < DEFENSE_PUNCHES.length; j++) {
        if (move.includes(DEFENSE_PUNCHES[j])) reaction = "MISS";
        continue
      }

      for (let j = 0; j < ALL_KICKS.length; j++) {
        if (move.includes(ALL_KICKS[j])) action = "KICK";
        continue
      }

      for (let j = 0; j < ALL_KNEES.length; j++) {
        if (move.includes(ALL_KNEES[j])) action = "KNEE";
        continue
      }

      for (let j = 0; j < ALL_TAKEDOWNS.length; j++) {
        if (move.includes(ALL_TAKEDOWNS[j])) action = "TAKEDOWN";
        continue
      }

      for (let j = 0; j < ALL_GNP.length; j++) {
        if (move.includes(ALL_GNP[j])) action = "GNP";
        continue
      }

      for (let j = 0; j < GNP_PUNCH.length; j++) {
        if (move.includes(GNP_PUNCH[j])) action = "GNP-PUNCH";
        continue
      }

      if (move.toLowerCase().includes('tries to pass')) { action = "pass"; reaction = "complete" }
      if (move.toLowerCase().includes('sweep')) { action = "sweep"; reaction = "complete" }
      if (move.toLowerCase().includes('great ko')) { action = "knock"; reaction = "out" }
      if (move.toLowerCase().includes('knocked out')) { action = "knock"; reaction = "out" }
      if (move.toLowerCase().includes('knocked down')) { action = "knock"; reaction = "down" }
      if (move.toLowerCase().includes('stops the fight')) { action = "knock"; reaction = "out" }
      if (move.toLowerCase().includes('out cold')) { action = "knock"; reaction = "out" }
      if (move.toLowerCase().includes('towel')) { action = "knock"; reaction = "out" }
      if (move.toLowerCase().includes('taps out')) { action = "tap"; reaction = "out" }
      if (move.toLowerCase().includes('breaks the clinch')) { action = "stop"; reaction = "break" }
      if (move.toLowerCase().includes('follows him')) { action = "follow"; reaction = "ground" }
      if (move.toLowerCase().includes('attempts to clinch')) { action = "clinch"; reaction = "attempt" }
      if (move.toLowerCase().includes('his feet')) { action = "back"; reaction = "standing" }
      if (move.toLowerCase().includes('missed badly')) { action = "missed"; reaction = "badly" }
      if (move.toLowerCase().includes('gains top')) { action = "gain"; reaction = "top" }
      if (move.toLowerCase().includes('buzzer sounds')) { action = "buzzer"; reaction = "sounds" }
      if (move.toLowerCase().includes('crippling')) { action = "submission"; reaction = "locked" }
      if (move.toLowerCase().includes('punishing')) { action = "submission"; reaction = "locked" }
      if (move.toLowerCase().includes('judges decision')) { action = "judges"; reaction = "decision" }

      if (move.toLowerCase().includes("tumbled")) reaction = "TUMBLED";
      if (move.toLowerCase().includes("dropped")) reaction = "DROPPED";
      if (move.toLowerCase().includes("slammed")) reaction = "SLAMMED";
      if (move.toLowerCase().includes("slammed head-first")) reaction = "SLAMMED-HEAD-FIRST";
      if (move.toLowerCase().includes("sprawls")) reaction = "DEFENSE";
      if (move.toLowerCase().includes("defends")) reaction = "DEFENSE";



      if (move.includes("block")) reaction = "BLOCKED";

      for (let j = 0; j < IS_HIT.length; j++) {
        if (move.includes(IS_HIT[j])) reaction = IS_HIT[j] == "Partially" ? "shrugs" : IS_HIT[j];
        continue
      }


    }

    console.log(action, reaction)
    if (!action || !reaction || action == undefined || reaction == undefined || !sounds || sounds == undefined) return

    if (reaction == "Vicious") {
      const audio2 = sounds[`crowd-reaction.mp3`];
      audio2.play();
    }
    if (reaction == "decision" || reaction == "standing" || reaction == "locked" || reaction == "down" || reaction == "top" || reaction == "TUMBLED" || reaction == "DROPPED") {
      const audio3 = sounds[`crowd-reaction2.mp3`];
      audio3.play();
    }

    if (reaction == "out" || reaction == "locked" || reaction == "Crushing" || reaction == "SLAMMED" || reaction == "SLAMMED-HEAD-FIRST") {
      const audio3 = sounds[`crowd-reaction3.mp3`];
      audio3.play();
    }

    if (action == "GNP-PUNCH" && reaction != "shrugs" && reaction != "BLOCKED") reaction = "Solid" + Math.ceil(4 * Math.random());
    if (action == "PUNCH" && reaction == "Solid") reaction += Math.ceil(2 * Math.random());
    if (action == "PUNCH" && reaction == "MISS") reaction += Math.ceil(2 * Math.random());
    if (action == "tap" && reaction == "out") reaction += Math.ceil(2 * Math.random());
    if (action == "knock" && reaction == "out") {

      const audio1 = sounds[`knock-down.mp3`];
      audio1.play();


    }

    const audio = sounds[`${action.toLocaleLowerCase()}-${reaction.toLocaleLowerCase()}.mp3`];
    audio != undefined && audio.play();
  }

  const playStadium = () => {

    stadium.play();

  }

  const Tally = styled.div`
 display: ${props => props.visible == true ? "flex" : "none"};
 justify-content:space-evenly;
 width:100%;
 color:#fff;
 margin-top:40px;
 font-size: ${props => props.largefont == true ? "22px" : "18px"};
 `
  const Div = styled.div`
  margin-top:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: rgba(0,0,0,0.5)
  `;

  const Head = styled.div`
 display: ${props => props.visible == true ? "flex" : "none"};
 justify-content:center;
 color:#fff;
 width:100%;
 padding:10px;
 `

  const PlayByPlay = styled.div`
 display: ${props => props.visible == true ? "flex" : "none"};
flex-direction:column;
 justify-content:space-evenly;
 display:none;
 width:80%;
 overflow:hidden;
 font-family: 'Roboto', sans-serif;

 `

  const Turn = styled.div`
 display: ${props => props.visible == true ? "flex" : "none"};
 flex-direction:column;
 z-index:99;
 height: 285px;
 justify-content:center;
 align-items:center;
 width:100%;
 font-family: 'Roboto', sans-serif;
 
 `

  const Breakdown = css`
 display:none;
 `
  const Fdiv = css`
  width:50%;
  text-align:right;
  display:flex;
  text-shadow:2px 2px #000;
  flex-direction:column;
  justify-content: right;
  `
  const Rdiv = css`
  width:50%;
  text-align:left;
  display:flex;
  text-shadow:2px 2px #000;
  flex-direction:column;
  justify-content: right;
  `

  const Ul = css`
 list-style-type: none;
 width:100%;
 margin-right:10px;
 margin-left:-50px;
 `
  const btn = css`
 color: green;
 cursor: pointer;
 `
  const Vital = styled.div`
 width: 75px;
 height: 15px;
 background-color: red;
 margin-left:5px;

 `

  const Inner = styled.div`
 width: 100%;
 height: 100%;
 background-color: white;
 display:flex;
 align-items:center;
justify-content:center;
 `

  const Fighter = styled.div`
 width: 250px;
 height: 30px;
 font-size: 20px;
 display:flex;
 align-items:center;
 justify-content:center;
 `

  const ViewBtn = styled.div`
 font-size:16px;
 font-style:bold;
 color: #fff;
 border-radius: 100px;
 border: 2px solid #fff;
 height:150px;
 width: 150px;
 outline:none;
 display:none;
 cursor:pointer;
 display: ${props => props.visible == true ? "flex" : "none"};
 justify-content:center;
 align-items:center;
 margin-top: 0px;
 margin-left: 5px;
 margin-right: 5px;
 background-color: #282c34;
 white-space: nowrap;

 `;

  const AutoBtn = styled.div`
 font-size:16px;
 font-style:bold;
 flex-direction:column;
 color: #fff;
 width: 150px;
 outline:none;
 display:none;
 cursor:pointer;
 display: ${props => props.visible == true ? "flex" : "none"};
 justify-content:center;
 align-items:center;
 margin-top: 0px;
 margin-left: 5px;
 margin-right: 5px;
 white-space: nowrap;
 `;

  const EndBtn = styled.div`
 font-size:16px;
 font-style:bold;
 color: green;
 border-radius: 100px;
 border: 2px solid green;
 height:150px;
 width: 150px;
 outline:none;
 display:none;
 cursor:pointer;
 display: ${props => props.visible == true ? "flex" : "none"};
 justify-content:center;
 align-items:center;
 margin-top: 0px;
 margin-left: 5px;
 margin-right: 5px;
 background-color: #282c34;
 white-space: nowrap;
 `;

  const Frame = styled.div`
 display:flex;
 flex-direction: column;
 justify-content:center;
 align-items:center;
 `

  const Row = styled.div`
 margin-top:5px;
display:flex;
min-width:100px;
justify-content:center;
font-size:20px;
 `

  const Span = styled.span`
width:25px;
text-shadow:#000 2px 2px 2px;
 `
  const Round = styled.div`
 font-size: 20px;
 font-style: bold;
 `

  return (
    <div className="App" style={{ display: "flex", justifyContent: "center" }}>
      <video id="video-div" style={{ position: "fixed", zIndex: -1, height: "650px" }} autoPlay={"autoplay"} loop muted playsInline webkit-playsinline={true} src="/assets/background.mp4"></video>
      <canvas style={{ position: "absolute" }} id="scene-div" width={1000} height={650}></canvas>
      <div style={{ position: "relative" }}>
        <Tally id="result-div" visible={sims == 1 || paramId ? false : true} largefont={window.innerWidth < 900 ? false : true} >
        </Tally>
        <Head id="header-div" visible={sims == 1 || paramId ? true : false}>
          <Frame>
            <Fighter className="megalogo" style={{ width: window.innerWidth < 900 ? "130px" : "250px" }} id="fighter1-div">{_f1name}</Fighter>
            <Row><Span className="fa">&#xf21e;</Span><Vital><Inner id="f1hp-div"></Inner></Vital></Row>
            <Row><Span className="fa">&#xf604;</Span><Vital><Inner id="f1cardio-div"></Inner></Vital></Row>
          </Frame>
          <Frame>
            <Round className="megalogo" id="round-div">1st</Round>
            <Round className="megalogo">Round</Round>
            <Row className="megalogo" id="clock-div">|00:00:00|</Row>
          </Frame>
          <Frame>
            <Fighter className="megalogo" style={{ width: window.innerWidth < 900 ? "130px" : "250px" }} id="fighter2-div">{_f2name}</Fighter>
            <Row><Span className="fa">&#xf21e;</Span><Vital><Inner id="f2hp-div"></Inner></Vital></Row>
            <Row><Span className="fa">&#xf604;</Span><Vital><Inner id="f2cardio-div"></Inner></Vital></Row>

          </Frame>

        </Head>

        <header className="App-header">

          <PlayByPlay visible={sims == 1 || paramId ? true : false} id="pbp-div"></PlayByPlay>
          <Turn visible={sims == 1 || paramId ? true : false} id="turn-div"></Turn>
          {fighter_data &&
          <EndBtn visible={sims == 1 || paramId ? false : true} style={{
            transform:
              sims != 1 && (!paramId || paramId == undefined)
                ? 'translateY(-100px)'
                : 'none',
          }} id="end-btn" onClick={props.history.goBack}>End Simulation</EndBtn>}
          <AutoBtn visible={sims == 1 || paramId ? true : false} id="auto-btn" >

          <ViewBtn visible={sims == 1 || paramId ? true : false} id="next-btn" onClick={nextMove}>Start Fight</ViewBtn>

            <Div>
              <input type="radio" id="off" name="speed" value="off" onChange={handleAutoPlay}></input>
              <label htmlFor="off" >Off</label>
              <input type="radio" id="slow" name="speed" value="slow" onChange={handleAutoPlay}></input>
              <label htmlFor="off">Slow</label>
              <input type="radio" id="fast" name="speed" value="fast" onChange={handleAutoPlay}></input>
              <label htmlFor="off">Fast</label>
            </Div>
            <div>Auto Play</div>

          </AutoBtn>

        </header>

      </div>

    </div>
  );
}

export default Sim;
