import gql from 'graphql-tag';

export const ADD_USER = gql`
  mutation addUser($firstName: String!, $lastName: String!, $email: String!) {
    addUser(firstName: $firstName, lastName: $lastName, email: $email) {
      user{
        _id
      }
    }
  }
`;
export const ADD_ORDER = gql`
  mutation addOrder($products: [ID]!) {
    addOrder(products: $products) {
      purchaseDate
      products {
        _id
      name
      description
      price
      quantity
      category {
        name
      } 
      }
    }
  }
`;

export const NEW_VISIT = gql`
mutation newVisit($ID: ID!) {
  newVisit(ID: $ID) {
   
     USER_ID
    
    
  }
}
`;