export const UPDATE_CLASS = "UPDATE_CLASS";
export const UPDATE_FIGHTERS = "UPDATE_FIGHTER";
export const UPDATE_FIGHTER1 = "UPDATE_FIGHTER1";
export const UPDATE_FIGHTER2 = "UPDATE_FIGHTER2";
export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const UPDATE_FIGHTS = "UPDATE_FIGHTS";
export const UPDATE_CARDS = "UPDATE_CARDS";
export const UPDATE_CARD_TITLE = "UPDATE_CARD_TITLE"
export const UPDATE_CARD_SIZE = "UPDATE_CARD_SIZE"
export const UPDATE_EMAIL = "UPDATE_EMAIL"
export const UPDATE_NAME = "UPDATE_NAME"
export const UPDATE_MY_FIGHTERS = "UPDATE_MY_FIGHTERS"
export const UPDATE_CURRENT_FIGHTER = "UPDATE_CURRENT_FIGHTER"
export const UPDATE_WINNERS = "UPDATE_WINNERS"
export const UPDATE_LAST_CARD = "UPDATE_LAST_CARD"
export const UPDATE_CARD_STARTED = "UPDATE_CARD_STARTED"

