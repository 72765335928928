import styled, { css } from 'styled-components';
import { QUERY_FIGHTS,QUERY_CARDS } from "../utils/queries";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FIGHTS,UPDATE_CARDS,UPDATE_CARD_TITLE,UPDATE_CARD_SIZE,UPDATE_LAST_CARD,UPDATE_CARD_STARTED } from '../utils/actions';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';
import { Bracket, RoundProps } from 'react-brackets';
import CustomSeed from './CustomSeed';
import moment from 'moment';
import {FaCaretLeft,FaCaretRight} from 'react-icons/fa'



// This files contains content and UI logic for main part of the UI showing the product and the details

function Card(props) {

    const state = useSelector((state) => {
        return state
    });

    const {startDate,lastCard,cardStarted} = state;

    console.log("last card",lastCard,cardStarted)
    const dispatch = useDispatch();
  

    let PAGE = parseInt(props.location.search.split("page=")[1]) || 0;

    const [getFights, { loading:fights_loading, data:fights_data }] = useLazyQuery(QUERY_FIGHTS) 
    
    
    const { loading:cards_loading, data:cards_data } = useQuery(QUERY_CARDS, {
        variables: { skip:false,page: PAGE +1}  }) 
    
    useEffect(() => {

      

        const FIGHTS = {};
        const CARDS = [];
    
        if(cards_data) {



            console.log(cards_data)
            const {cards} = cards_data;
            const cardId = cards[0]._id;

            getFights({ variables: { _id: cardId } });

             if(fights_data) 
             {
             console.log(fights_data)


            

            const {fights} = fights_data;
            CARDS.push(cardId)

            fights.forEach(fight=>{

                if(cardId in FIGHTS)
                {
                    FIGHTS[cardId].push(fight)
                }
                else
                {
                    FIGHTS[cardId] = [fight]
                
                }
            })


            console.log("cards and fights",CARDS,FIGHTS);


            const curr = 0
            const {cards:cardSizes} = cards_data;
            console.log("cardSizes",cardSizes);

            const lastCard = cardSizes[cardSizes.length-1];
            const lastCardStarted = CARDS[0].toString() in FIGHTS && FIGHTS[CARDS[0].toString()].length > 0;

            console.log("lastCardStarted",lastCardStarted,CARDS.map(card=>card.toString()),lastCard)

            const cardSize = cardSizes.filter(c=>c._id.toString()==CARDS[curr].toString())[0].FIGHTERS.length

            
            dispatch({
                type: UPDATE_LAST_CARD,
                lastCard: toFights(lastCard.FIGHTERS)
              });
            dispatch({
                type: UPDATE_CARD_STARTED,
                cardStarted: lastCardStarted
              });

            dispatch({
                type: UPDATE_FIGHTS,
                fights: FIGHTS
              });
            dispatch({
                type: UPDATE_CARDS,
                cards: CARDS
              });
            

             dispatch({
                type: UPDATE_CARD_TITLE,
                title: cardSizes[curr].createdAt
                   });
             dispatch({
                    type: UPDATE_CARD_SIZE,
                    cardSize: cardSize
                    });
    

                   
       
        } 

    }



      }, [cards_data, fights_data, dispatch]);

    function gotoFight(event) {

     //   if(fighter1 && fighter2 && rounds && sims) window.location = "/sim";

    }



    function daysElapsed(createdAt,start)
    {
       console.log(parseInt(createdAt),start)
       const cardDate = moment(parseInt(createdAt)) 

       const dur = moment.duration(cardDate.diff(start))
        
       console.log(moment(start).format())

       return Math.floor(dur.asDays())+1;
    }

    function prevPage(event)
    {
        if(PAGE > 0) { PAGE -= 1; window.location = "/card?page="+PAGE;}
        
    }

    function nextPage(event)
    {
        PAGE += 1; 
        window.location = "/card?page="+PAGE;
        
    }


    function filterFights(curr= 0) {


       // const currentCard = state.cards[curr];
        const FIGHTS = state.fights;
        const CARDS = state.cards;
        const CARD = FIGHTS[CARDS[curr]]  
       
        console.log(state.cardSize)

        return toBracket(CARD) 
    }

    const toBracket = (card) => {

        if(card == undefined || card == null) return ""

        const rounds = [];
        const seeds = [];
        let round = state.cardSize ;
        let index = 0;

        while(round/2 >=1)
        {

            round = Math.floor(round/2) ;
            rounds.push(round)
        }

        rounds.forEach(el=>{

            const obj = {

                title:"ROUND "+el,
                seeds:[]
            }

            for (let i=0; i < el; i++)
            {
                const fight = card[index+i] || {_id:"TBA",createdAt:"TBA",WINNER_NAME:"",WINNER_ID:"TBA",LOSER_NAME:"",LOSER_ID:"TBA"};

                const ob1 = {
                id: fight._id,
                date: fight.createdAt,
                teams:[{name: fight.WINNER_NAME,id:fight.WINNER_ID},{name:fight.LOSER_NAME,id:fight.LOSER_ID}]
               }

               obj.seeds.push(ob1)

            }

            seeds.push(obj)

            index += el;

            
        })

        console.log(seeds)
    

        return seeds

    }

    const toFights = (card) => {

        const newCard = [];

        for (let i=0; i < card.length; i++)
        {
            const newPosition = Math.floor(i/2);
            
            if(i%2==0)
            {

                newCard[newPosition] = {};
                newCard[newPosition].WINNER_NAME = card[i].NAME;
                newCard[newPosition].WINNER_ID = card[i].FIGHTER_ID;
                newCard[newPosition].id = "TBA";
                newCard[newPosition].createdAt = "TBA";
                
            }
            else{
                newCard[newPosition].LOSER_NAME = card[i].NAME;
                newCard[newPosition].LOSER_ID = card[i].FIGHTER_ID;
            }
        }

        return newCard
    }

    const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin-top:20px;
    `;

    const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    margin-top:10px;
    margin-bottom:10px;
    align-items:center;
    color: #fff;
    text-shadow: 1px 1px #000;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

 

    return (

            <div>
            <Container style={{fontSize:"24px"}} className="metallic">NFC {daysElapsed(state.title,startDate)}</Container>
            <Wrapper id="card-title"><FaCaretLeft style={{fontSize:"32px"}} onClick={prevPage}/>
            {cardStarted ? "Results of "+new Date(parseInt(state.title)).toLocaleDateString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : "Card starts Today at 17:30 PST"}<FaCaretRight style={{fontSize:"32px"}} onClick={nextPage}/></Wrapper>
           {console.log( "card isss",cardStarted,cardStarted ?
                 filterFights() 
                : toBracket(lastCard))}
           
            <Bracket rounds={
                cardStarted ?
                filterFights() 
                : toBracket(lastCard)
                
                } renderSeedComponent={CustomSeed} />
            </div>

       
    )
}

export default Card;