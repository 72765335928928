import styled, { css } from 'styled-components';
import { QUERY_FIGHTERS } from "../utils/queries";
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';

function Login(props) {

 const request =  { method: 'POST',
 body:{}
}

    const submitRegister = (event) => {
        event.preventDefault()
        const email = event.target.email.value;
        const name = event.target.name.value;
      // if (!verify(stats)) return 

       fetch(`api/user/new`,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email:event.target.email.value,name:event.target.name.value,password:event.target.password.value,openSeaAccount:event.target.openSeaAccount.value})
      })
      .then(result =>  {
        if(result.ok == true) welcome(email,name);
      })
      .catch(err=>reset(err,event))

    }


    const welcome = (email,name) => {

      console.log(email,name)

      fetch(`api/mail/welcome1`,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email:email,name:name})
      })
      .then(result =>  {
        console.log(result)
         window.location = "/";
        
      })
      .catch(err=>{ 
        console.log(err);
        window.location = "/";})

    }

    const submitLogin = (event) => {
        event.preventDefault()
      // if (!verify(stats)) return
        console.log("submitted")

       fetch(`api/user/login`,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email:event.target.email.value,password:event.target.password.value})
      })
     .then(res=>{if(res.ok == true) window.location = "/"})
     .catch(err=>reset(err,event))
   
      

    }

    const logout =()=> {

      fetch('api/user/logout',request)
      .then(res=>window.location = "/")
      .catch(err=>console.log(err))

    }


    const verify = (stats) => {


    }

    const reset = (err,event) => {
      console.log(err)
    }


    const gotoSignup = (event) => {

      window.location = "/signup"

    }

    const gotoForgot = (event) => {

      window.location = "/forgot"

    }

    const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    width:300px;
    `;

    const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

    const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const Div = styled.div`
    display:flex;
    justify-content:space-between;
    width: 225px;
    `;

    const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

    const Input = styled.input`
    width:300px;
    `

    return (

      <header className="App-header">
      <Wrapper>

        
        <Container style={{textShadow:"1px 1px #000"}}>
            <form onSubmit={submitRegister} style={{display: props.match.url.indexOf("/signup") > -1 ? "" : "none"}}>
            <h3>NEW ACCOUNT</h3>
                <label htmlFor="email">email:</label><br></br>
                <Input type="text" name="email"></Input><br></br>
                <label htmlFor="name">name:</label><br></br>
                <Input type="text" name="name"></Input><br></br>
                <label htmlFor="name">Open Sea Account: <br></br><small>(Optional, but needed to receive NFTs)</small></label><br></br>
                <Input type="text" name="openSeaAccount"></Input><br></br>
                <label htmlFor="password">password:</label> <br></br>
                <Input type="password" name="password"></Input><br></br>
                <label htmlFor="confirm-password">confirm password:</label><br></br>
                <Input type="password" name="confirm-password"></Input><br></br>
                <button type="submit" style={{marginTop:"10px"}}>Submit</button>
            </form>

            <form onSubmit={submitLogin} style={{display: props.match.url.indexOf("/login") > -1 ||  props.match.url.indexOf("/home") > -1  ? "" : "none"}}>
            <h3>LOGIN</h3>
                <label htmlFor="email">email:</label><br></br>
                <Input type="text" name="email"></Input><br></br>
                <label htmlFor="password">password:</label> <br></br>
                <Input type="password" name="password"></Input><br></br>
                <button type="submit" style={{marginTop:"10px"}}>Submit</button>
                <h3 onClick={logout}>LOGOUT</h3>
                <h3 onClick={gotoSignup}>SIGNUP</h3>
                <h3 onClick={gotoForgot}>Forgot Password?</h3>
            </form>
           
            
       
          
        </Container>
               
               
           
        </Wrapper>
        </header>
    )
}

export default Login;