import gql from 'graphql-tag';

export const QUERY_FIGHTERS = gql`
{
  fighters {
    _id
    IMG
    NAME
    WEIGHT_CLASSES
    type
  }
}
`;

export const QUERY_FIGHTS = gql`
query fights($_id: ID!){
  fights(_id:$_id) {
    _id
    CARD_ID
    WINNER_ID
    LOSER_ID
    WINNER_NAME
    LOSER_NAME
    createdAt
  }
}
`;

export const QUERY_RANKINGS = gql`
{
  rankings {

    FIGHTER_ID
    RATING

  }
}
`;

export const QUERY_RECORD = gql`
query record($_id: ID!) {

  record(_id:$_id) {
    _id
    CARD_ID
    WINNER_ID
    LOSER_ID
    WINNER_NAME
    LOSER_NAME
    createdAt
  }
}
`;

export const QUERY_CARDS = gql`
  query cards($page: Int!) {
    cards(page:$page) {
      
      _id
      createdAt
      FIGHTERS {
        NAME
        FIGHTER_ID
      }
    }
  }
`;

export const QUERY_ONE_FIGHT = gql`
query fight($_id: ID!) {

  fight(_id:$_id) {
    
                _id
                ACTION

      }
    
  }


`;

export const QUERY_ONE_FIGHTER = gql`
query oneFighter($_id: ID!) {

  oneFighter(_id:$_id) {
    
    _id
    IMG
    NAME

      }
    
  }


`;

export const QUERY_MY_FIGHTERS = gql`
query myFighters($fighters: [ID]!) {

  myFighters(fighters:$fighters) {
    
    _id
    owner
    type
    XP
    IMG
    NAME
    WEIGHT_CLASSES
    STRENGTH
    KO_POWER
    CONDITIONING
    CHIN
    KICKS
    PUNCHES
    CLINCH
    TAKEDOWNS
    SPRAWL
    AGGRESIVENESS
    KILLER_INSTINCT
    ECCENTRICITY
    GROUND_AND_POUND
    SUBMISSIONS
    GUARD
    SCRAMBLES
    STRAT1_STRIKING
    STRAT1_GRAPPLING
    STRAT2_CLINCH
    STRAT2_SHOOT
    STRAT3_GROUND_AND_POUND
    STRAT3_POSITIONING
    STRAT3_SUBMISSION
    STRAT3_STAND
    STRAT4_POSITIONING
    STRAT4_SCRAMBLING
    STRAT4_SUBMISSION
    STRAT5_KICKING
    STRAT5_PUNCHING
    STRAT6_PULL_GUARD
    STRAT6_STAND

      }
    
  }


`;


export const QUERY_MY_XP = gql`
query myXP($fighter:  ID! ) {

  myXP(fighter:  $fighter ) {

    
      XP
      FIGHT_ID
      PLAYER_ID
      PLAYER_NAME
      OPPONENT_NAME
      createdAt
        

      }
    
  }


`;


export const QUERY_WINNERS = gql`
 {

 winners {
    
    WINNER_OWNER_ID
    LOSER_OWNER_ID
    WINNER_ID
    CARD_ID
    LOSER_ID
    WINNER_NAME
    LOSER_NAME
    WINNER_BODY
    createdAt
        

      }
    
  }


`;


