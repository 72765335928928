import { Bracket, RoundProps, Seed, SeedItem, SeedTeam, RenderSeedProps } from 'react-brackets';
import React from 'react';

const CustomSeed = ({seed, title, breakpoint, roundIndex, seedIndex}: RenderSeedProps) => {
  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not

  // mobileBreakpoint is required to be passed down to a seed
  return (
    <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
      <SeedItem>
        <div style={{cursor:"pointer"}} onClick={()=>{if(seed.id !=undefined && seed.id !="TBA" && seed.teams[1].id!="TBA" && seed.teams[0].id!="TBA")window.location = `/sim?id=${seed.id.toString()}&fighter1Id=${seed.teams[0].id}&fighter2Id=${seed.teams[1].id}`}}>
          <SeedTeam style={{ color: 'red' }}>{seed.teams[0]?.name || 'TBA '}</SeedTeam>
          <SeedTeam>{seed.teams[1]?.name || 'TBA '}</SeedTeam>
        </div>
      </SeedItem>
    </Seed>
  );
};



export default CustomSeed