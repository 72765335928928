import styled, { css } from 'styled-components';
import { QUERY_MY_FIGHTERS,QUERY_RECORD,QUERY_ONE_FIGHTER } from "../utils/queries";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';
const ObjectId = require('mongoose').Types.ObjectId;

function Record(props) {

  String.prototype.toObjectId = function () {
        if (this == "") return null
        else return new ObjectId(this.toString())
      };
      
  
  const state = useSelector((state) => {
    return state
  });

  const { email, isLoggedIn, myFighters} = state;

  const currentFighter = props.location.search.split("fighter=")[1] || null;


  if(!currentFighter || currentFighter == undefined) window.location = "/home"




  const dispatch = useDispatch();
  
  const {data:fighter_data} = useQuery(QUERY_ONE_FIGHTER , {
    variables: { skip: !isLoggedIn || currentFighter  === null, _id: currentFighter.toObjectId() }  }) 

  const {data:record_data} = useQuery(QUERY_RECORD , {
        variables: { skip: !isLoggedIn || currentFighter  === null, _id: currentFighter.toObjectId() }  }) 
  
  const gotoFight = (event) => {

   const {paramid,fighter1id,fighter2id} = event.target.dataset;
   window.location = `sim?paramId=${paramid}&fighter1Id=${fighter1id}&fighter2Id=${fighter2id}`;

  }
  
  useEffect(() => {

     if(fighter_data) {
         console.log(fighter_data)
         const {oneFighter} = fighter_data;

        
            if(oneFighter._id.toString()==currentFighter) setStats(oneFighter)
            
         
     }


    }, [fighter_data]);

    useEffect(() => {

        if(record_data) {

            let wins = 0, losses = 0;
            record_data.record.reverse()
            record_data.record.forEach(fight=>{

                 if(fight.WINNER_ID.toString()==currentFighter)  wins++
                 else if(fight.LOSER_ID.toString()==currentFighter) losses++

            })

            console.log(`WINS ${wins} LOSSES ${losses}`)
            setWins(wins);
            setLosses(losses);

        }
   
   
       }, [record_data]);

  const [wins, setWins] = useState(0)
  const [losses, setLosses] = useState(0)
  const [stats, setStats] = useState({
    type: "GAME",
    NAME: "",
    IMG: "",
    WEIGHT_CLASSES: [],
    XP: 0,
    STRENGTH: 6,
    KO_POWER: 6,
    CONDITIONING: 6,
    CHIN: 6,
    KICKS: 6,
    PUNCHES: 6,
    CLINCH: 6,
    TAKEDOWNS: 6,
    SPRAWL: 6,
    AGGRESIVENESS: 6,
    KILLER_INSTINCT: 6,
    ECCENTRICITY: 6,
    GROUND_AND_POUND: 6,
    SUBMISSIONS: 6,
    GUARD: 6,
    SCRAMBLES: 6,
    STRAT1_STRIKING: 65,
    STRAT1_GRAPPLING: 35,
    STRAT2_CLINCH: 50,
    STRAT2_SHOOT: 50,
    STRAT3_GROUND_AND_POUND: 55,
    STRAT3_POSITIONING: 40,
    STRAT3_SUBMISSION: 5,
    STRAT3_STAND: 0,
    STRAT4_POSITIONING: 40,
    STRAT4_SCRAMBLING: 55,
    STRAT4_SUBMISSION: 5,
    STRAT5_PUNCHING: 35,
    STRAT5_KICKING: 65,
    STRAT6_PULL_GUARD: 0,
    STRAT6_STAND: 100,
    wins:0,
    losses:0
  })


  const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    `;

  const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

  const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const IncrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:4px;
    padding-right:4px;
    `;

    const DecrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:6px;
    padding-right:4px;
    `;

  const Div = styled.div`
    display:flex;
    justify-content:center;
    font-size:20px;
    width: 225px;
    text-align:center;
    `;

  const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

 

  return (

    <header className="App-header">
      <Wrapper>

        <h3 style={{textShadow:"1px 1px #000"}}>{stats.NAME}'S RECORD</h3>
        <Div id="winlossdiv" className="metallic">{`WINS ${wins} LOSSES ${losses}`}</Div> 

        <Container>

        {record_data && (record_data.record.map(fight=>(

            <ViewBtn data-paramId={fight._id} data-fighter1Id={fight.WINNER_ID} data-fighter2Id={fight.LOSER_ID} onClick={gotoFight} className={fight.WINNER_ID.toObjectId()==currentFighter?"button-whitegold":"button-platinum"} style={{width:"320px"}}>
                
                {
                fight.WINNER_ID.toObjectId()==currentFighter?
                "WIN "+fight.LOSER_NAME+" "+new Date(parseInt(fight.createdAt)).toLocaleDateString("en-US",{ year: '2-digit', month: '2-digit', day: 'numeric' }) : 
                
                "LOSS "+fight.WINNER_NAME+" "+new Date(parseInt(fight.createdAt)).toLocaleDateString("en-US",{  year: '2-digit', month: '2-digit', day: 'numeric' })
                }
                
            </ViewBtn>
            

        )))}
      

        </Container>

       


      </Wrapper>
    </header>
  )
}

export default Record;