import styled, { css } from 'styled-components';
import { QUERY_MY_FIGHTERS } from "../utils/queries";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import FighterBtn from './fighter-btn';

function Edit(props) {


  
  const state = useSelector((state) => {
    return state
  });

  const { email, isLoggedIn, myFighters} = state;

  const currentFighter = props.location.search.split("fighter=")[1] || null;


  if(!currentFighter || currentFighter == undefined) window.location = "/home"


  const STRAT_NAMES = {STRAT1:"Standing",STRAT2:"Grappling",STRAT3:"Ground: Top",STRAT4:"Ground: Bottom",STRAT5:"Striking",STRAT6:"Takedown fails"};


  let canvas, ctx;


  const dispatch = useDispatch();
  
  const {data:fighter_data} = useQuery(QUERY_MY_FIGHTERS , {
      variables: { skip:myFighters==null||myFighters == undefined||myFighters.length==0 ? true : false,fighters:myFighters||[]},
    }) 

  
  useEffect(() => {

     if(fighter_data) {
         console.log(fighter_data)
         const {myFighters} = fighter_data;


         myFighters.forEach(f=>{

            if(f._id.toString()==currentFighter)
            {
  
            setStats(f)
            }
          
         })
     }


    }, [fighter_data]);

  const [stats, setStats] = useState({
    type: "GAME",
    NAME: "",
    IMG: "",
    WEIGHT_CLASSES: [],
    XP: 0,
    STRENGTH: 6,
    KO_POWER: 6,
    CONDITIONING: 6,
    CHIN: 6,
    KICKS: 6,
    PUNCHES: 6,
    CLINCH: 6,
    TAKEDOWNS: 6,
    SPRAWL: 6,
    AGGRESIVENESS: 6,
    KILLER_INSTINCT: 6,
    ECCENTRICITY: 6,
    GROUND_AND_POUND: 6,
    SUBMISSIONS: 6,
    GUARD: 6,
    SCRAMBLES: 6,
    STRAT1_STRIKING: 65,
    STRAT1_GRAPPLING: 35,
    STRAT2_CLINCH: 50,
    STRAT2_SHOOT: 50,
    STRAT3_GROUND_AND_POUND: 55,
    STRAT3_POSITIONING: 40,
    STRAT3_SUBMISSION: 5,
    STRAT3_STAND: 0,
    STRAT4_POSITIONING: 40,
    STRAT4_SCRAMBLING: 55,
    STRAT4_SUBMISSION: 5,
    STRAT5_PUNCHING: 35,
    STRAT5_KICKING: 65,
    STRAT6_PULL_GUARD: 0,
    STRAT6_STAND: 100
  })

  let XP = stats.XP;
  //alert(stats.type)

  const changeHandler = (event) => {

    // const key = event.target.id;
    // stats[key] = event.target.value;
    // setStats(stats)
  }

  const changeStratHandler = (event) => {

  }

  const draw = (bodyFiles) => {
 
      stats["IMG"] = bodyFiles
      setStats(stats)
    
    const bodyParts = {
    bodyImg : new Image(),
    faceImg : new Image(),
    mouthImg : new Image(),
    bodyHairImg : new Image(),
    tattooChestImg : new Image(),
    tattooBicepRightImg : new Image(),
    tattooForearmRightImg : new Image(),
    tattooForearmLeftImg : new Image(),
  //  tattooShoulderRightImg : new Image(),
    tattooShoulderLeftImg : new Image(),
    tattooShinRightImg : new Image(),
    tattooShinLeftImg : new Image(),
    tattooHeadImg : new Image(),
    shortsImg : new Image(),
    hairImg : new Image(),
    beardImg : new Image()
    }

   
    let imgsLoaded = 0;
    const imgsArray = [];

    for (const part in bodyParts)
    {
    const file = bodyFiles[part.slice(0, -3) +"_file"]
    const img = bodyParts[part]

    if(!file.includes("none"))
    {
    img.src = file
    imgsArray.push(img)
    }
    else imgsLoaded++;

    img.onload = function () {
    
     // 
     imgsLoaded++;
     if(imgsLoaded >= 14)
     {
        clearCanvas();
       ctx.save();
       ctx.translate(-200,0);
       ctx.scale(-1,1)
     
      
        imgsArray.forEach(img=> ctx.drawImage(img, img.width * -1, 0))
        ctx.restore();
     
     }
    }
  }

  

  }

  const clearCanvas = () => {

    ctx.clearRect(0,0,canvas.width* 2,canvas.height * 2)
  }
 
  const doIncrement = (event) => {

    const strat = event.target.parentNode.querySelector(`[id^="STRAT"]`)
    strat.value <100 && strat.value++;
  }

  const doDecrement = (event) => {

    
    const strat = event.target.parentNode.querySelector(`[id^="STRAT"]`)
    strat.value > 0 && strat.value--;
  }

  const doIncrementStat = (event) => {
    if(XP == 0 ) return
    const stat = event.target.parentNode.querySelector(`input`)
    if(stat.value <12) {
        stat.value = parseFloat(stat.value)+ .5;
        XP-= .5;
        document.querySelector("#XP").value = XP;
    }
  }

  const doDecrementStat = (event) => {

    
    const stat = event.target.parentNode.querySelector(`input`)
    const {id} = stat;
    if(stat.value > stats[id]) {
         stat.value = parseFloat(stat.value)- .5;
         XP += .5;
         document.querySelector("#XP").value = XP;

    }
  }

  const getBody = (target) => {

   

    const obj = {};

    const clonedStats = {...stats}

    delete clonedStats._id;
    delete clonedStats.IMG; 
    delete clonedStats.type; 
    delete clonedStats.NAME;
    delete clonedStats.WEIGHT_CLASSES;
    delete clonedStats.owner;
    delete clonedStats.__typename;

    for (const stat in clonedStats)
    {
       
        console.log(stat)
        obj[stat] = parseFloat(document.querySelector("#"+stat).value);
        console.log(obj)

    }

    obj._id = stats._id;
  

    return obj

  }

  const changeStatus = (event) =>  {

    const body = {};
    body._id = stats._id;
    body.type = "GAME";

    fetch(`api/fighter/activate`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(result => window.location = "/")
      .catch(err => reset(err, event))
  }

  const submitFighter = (event) => {

      event.preventDefault()

    if(myFighters.length == 0 || !myFighters.includes(currentFighter)) window.location = "/home"
    if(!email || !isLoggedIn || isLoggedIn == undefined) window.location = "/home"

    if (!verify(stats)) return

    console.log(verifyStrat())

    const body = getBody(event.target);

    console.log(body)


    event.target.setAttribute("data-locked", true)

    fetch(`api/fighter`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(result => window.location = "/")
      .catch(err => reset(err, event))

  }

  const verify = (stats) => {

    const valid = /[a-zA-Z]/

    if (stats.NAME.length < 4 || valid.test(stats.NAME) == false) { alert("Invalid name"); return false }

    const reducedNew = Array.from(document.querySelectorAll(".stat-class")).map(input=>parseFloat(input.value)).reduce((a,b)=>a+b);

    console.log(reducedNew)
     
    const onlyStats = {...stats};
    delete onlyStats.IMG; 
    delete onlyStats.type; 
    delete onlyStats.NAME;
    delete onlyStats.WEIGHT_CLASSES;
    delete onlyStats._id;
    delete onlyStats.owner;
    delete onlyStats.__typename;

   
    const statsArray = Object.values(onlyStats);
    statsArray.splice(17,15)

    const reducedOld = statsArray.reduce((a,b)=>a+b);
    console.log(reducedOld)

   if(reducedNew != reducedOld) return false

    return true

  }

  const verifyStrat = () => {

  
    for(let i=1; i <=6;i++)
    {
    const group = Array.from(document.querySelectorAll(`[id^="STRAT${i}"]`)).map(node=>parseFloat(node.value));
    console.log(group)
    const total = group.reduce((a,b)=>a+b)
    const strat = "STRAT"+i;
    console.log(total)

    if(total!= 100) return `The sum percentages for Strategy - ${STRAT_NAMES[strat]} must equal 100%`
    }

    return true

  }


  const reset = (err, event) => {

    // event.target.setAttribute("data-locked", false)
    alert("Error " + err)

  }

  const Container = styled.div`
    display:flex;
    font-size:16px;
    flex-direction:column;
    justify-content: center;
    margin-top:20px;
    `;

  const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    color: #fff;
    @media only screen and (max-width:1000px) {
        width:100%; 
    }
    `;

  const ViewBtn = styled.button`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    width: 150px;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #00A598;
    white-space: nowrap;
    `;

    const IncrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:4px;
    padding-right:4px;
    `;

    const DecrBtn = styled.span`
    font-size:16px;
    cursor:'pointer';
    padding-left:6px;
    padding-right:4px;
    `;

    const Div = styled.div`
    display:flex;
    font-weight:600;
    justify-content:space-between;
    text-shadow: 1px 1px #000;
    width: 100%;
    `;

  const Select = styled.select`
    font-size:16px;
    border-radius: 20px;
    border-color: #fff;
    height: 20px;
    text-align: center;
    display:flex;
    justify-content:center;
    outline:none;
    cursor:pointer;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    background-color: #000;
    white-space: nowrap;
    `;

    const Text = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    padding:5px 15px 5px 15px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    text-align: center;
    text-style:bold;
    font-size:16px;
    background-color: rgba(0,0,0,0.5);
    `;

 

  return (

    <header className="App-header">
      <Wrapper>

        <h3>FIGHTER EDITOR</h3>
        {stats.type == "inactive" && ( <>
        <Text>{stats.NAME}'s status is set to Inactive by default or user inactivity. Click "Go Pro" to enter the daily tournaments.</Text> 
        <ViewBtn className="button-platinum" id="gopro-btn" onClick={changeStatus} type="submit">Go Pro</ViewBtn>
        </>) }
        <Container>

            <form onSubmit={submitFighter}>

        <Div>
            <label htmlFor="XP">XP</label>  
            <input className="stat-class" name="XP" id="XP" type="Number" width="40px"  readOnly={true} value={stats.XP}  min="3" max="10" style={{ marginLeft: "10px",marginBottom:"15px" }}></input>      
        </Div>
          <input name="NAME" id="NAME" type="text" value={stats.NAME} readOnly={true} onChange={changeHandler} style={{textAlign:"center",fontSize:"18px"}}></input>
          <h3>Physical</h3>
          <Div>
            <label htmlFor="STRENGTH">Strength</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="STRENGTH" id="STRENGTH" type="Number" width="40px" step="0.5" readOnly={true} value={stats.STRENGTH} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="KO_POWER">KO Power</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="KO_POWER" id="KO_POWER" type="Number" width="40px" step="0.5" readOnly={true} value={stats.KO_POWER} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CONDITIONING">Conditioning</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="CONDITIONING" id="CONDITIONING" type="Number" width="40px" step="0.5" readOnly={true} value={stats.CONDITIONING} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CHIN">Chin</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="CHIN" id="CHIN" type="Number" width="40px" step="0.5" readOnly={true} value={stats.CHIN} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>Mental</h3>

          <Div>
            <label htmlFor="AGGRESIVENESS">Aggressiveness</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="AGGRESIVENESS" id="AGGRESIVENESS" type="Number" step="0.5" width="40px"  readOnly={true} value={stats.AGGRESIVENESS} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="KILLER_INSTINCT">Killer Instinct</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="KILLER_INSTINCT" id="KILLER_INSTINCT" type="Number" step="0.5" width="40px"  readOnly={true} value={stats.KILLER_INSTINCT} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="ECCENTRICITY">Eccentricity</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="ECCENTRICITY" id="ECCENTRICITY" type="Number" step="0.5"width="40px"  readOnly={true} value={stats.ECCENTRICITY} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>STANDING</h3>
          <Div>
            <label htmlFor="KICKS">Kicks</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="KICKS" id="KICKS" type="Number" width="40px" step="0.5"  readOnly={true} value={stats.KICKS} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="PUNCHES">Punches</label>
             <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="PUNCHES" id="PUNCHES" type="Number" width="40px" step="0.5"  readOnly={true} value={stats.PUNCHES} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="CLINCH">Clinch</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="CLINCH" id="CLINCH" type="Number" width="40px" step="0.5" readOnly={true} value={stats.CLINCH} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="TAKEDOWNS">Takedowns</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="TAKEDOWNS" id="TAKEDOWNS" type="Number" width="40px" step="0.5"  readOnly={true} value={stats.TAKEDOWNS} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="SPRAWL">Sprawl</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="SPRAWL" id="SPRAWL" type="Number" width="40px"  step="0.5" readOnly={true} value={stats.SPRAWL} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn> 
            </div>
            </Div>

          <h3>GROUND</h3>

          <Div>
            <label htmlFor="GROUND_AND_POUND">Ground and Pound</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="GROUND_AND_POUND" id="GROUND_AND_POUND" step="0.5"  type="Number" width="40px" value={stats.GROUND_AND_POUND} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="SUBMISSIONS">Submissions</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="SUBMISSIONS" id="SUBMISSIONS" step="0.5" type="Number" width="40px" value={stats.SUBMISSIONS} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="GUARD">Guard</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="GUARD" id="GUARD" step="0.5" type="Number" width="40px" value={stats.GUARD} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="SCRAMBLES">Scrambles</label>
            <div>
            <IncrBtn onClick={doIncrementStat}>+</IncrBtn>
            <input className="stat-class" name="SCRAMBLES" id="SCRAMBLES" step="0.5" type="Number" width="40px" value={stats.SCRAMBLES} min="3" max="10" style={{ marginLeft: "10px" }} onChange={changeHandler}></input>
            <DecrBtn onClick={doDecrementStat}>-</DecrBtn>
            </div>
          </Div>

          <h3>STRATEGY</h3>
          <h4>Standing</h4>

          <Div>
            <label htmlFor="STRAT1_STRIKING">Striking</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT1_STRIKING" id="STRAT1_STRIKING" type="Number" width="40px" defaultValue={stats.STRAT1_STRIKING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT1_GRAPPLING">Grappling</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT1_GRAPPLING" id="STRAT1_GRAPPLING" type="Number" width="40px" defaultValue={stats.STRAT1_GRAPPLING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Grappling</h4>

          <Div>
            <label htmlFor="STRAT2_CLINCH">Clinch</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT2_CLINCH" id="STRAT2_CLINCH" type="Number" width="40px" defaultValue={stats.STRAT2_CLINCH} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT2_SHOOT">Takedowns</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT2_SHOOT" id="STRAT2_SHOOT" type="Number" width="40px" defaultValue={stats.STRAT2_SHOOT} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Ground: Top</h4>

          <Div>
            <label htmlFor="STRAT3_GROUND_AND_POUND">Ground and Pound</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_GROUND_AND_POUND" id="STRAT3_GROUND_AND_POUND" type="Number" width="40px" defaultValue={stats.STRAT3_GROUND_AND_POUND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_POSITIONING">Positioning</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_POSITIONING" id="STRAT3_POSITIONING" type="Number" width="40px" defaultValue={stats.STRAT3_POSITIONING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_SUBMISSION">Submissions</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_SUBMISSION" id="STRAT3_SUBMISSION" type="Number" width="40px" defaultValue={stats.STRAT3_SUBMISSION} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT3_STAND">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT3_STAND" id="STRAT3_STAND" type="Number" width="40px"  defaultValue={stats.STRAT3_STAND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Ground: Bottom</h4>

          <Div>
            <label htmlFor="STRAT4_POSITIONING">Positioning</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_POSITIONING" id="STRAT4_POSITIONING" type="Number" width="40px" defaultValue={stats.STRAT4_POSITIONING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT4_SUBMISSION">Submissions</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_SUBMISSION" id="STRAT4_SUBMISSION" type="Number" width="40px" defaultValue={stats.STRAT4_SUBMISSION} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT4_SCRAMBLING">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT4_SCRAMBLING" id="STRAT4_SCRAMBLING" type="Number" width="40px" defaultValue={stats.STRAT4_SCRAMBLING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>Striking</h4>

          <Div>
            <label htmlFor="STRAT5_PUNCHING">Punching</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT5_PUNCHING" id="STRAT5_PUNCHING" type="Number" width="40px" defaultValue={stats.STRAT5_PUNCHING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT5_KICKING">Kicking</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT5_KICKING" id="STRAT5_KICKING" type="Number" width="40px" defaultValue={stats.STRAT5_KICKING} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <h4>If Takedown Fails:</h4>


          <Div>
            <label htmlFor="STRAT6_PULL_GUARD">Pull Guard</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT6_PULL_GUARD" id="STRAT6_PULL_GUARD" type="Number" width="40px" defaultValue={stats.STRAT6_PULL_GUARD} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <Div>
            <label htmlFor="STRAT6_STAND">Stand</label>
            <div>
            <IncrBtn onClick={doIncrement}>+</IncrBtn>
            <input name="STRAT6_STAND" id="STRAT6_STAND" type="Number" width="40px" defaultValue={stats.STRAT6_STAND} min="0" max="100" style={{ marginLeft: "10px" }} onChange={changeStratHandler}></input>
            <DecrBtn onClick={doDecrement}>-</DecrBtn>
            </div>
          </Div>

          <ViewBtn className="button-platinum" id="submit-btn" data-locked={false} type="submit">Submit</ViewBtn>

          </form>


        </Container>

       


      </Wrapper>
    </header>
  )
}

export default Edit;