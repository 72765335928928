import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';


import '../Sidebar.css'



function Sidebar({isLoggedIn} ) {



  const [url, setUrl] = useState(window.location.href)

  let opened = false

  const closeSidebar = event => {
    opened = false

    setTimeout(
      () => (document.querySelector('.sidebar-nav').style.left = '-100vw'),
      1500
    )
  }

  return (
    <div>
      {isLoggedIn ? (
          <div
          className="sidebar-nav"
          style={{
            height: '150vh',
            width: '100vw',
            backgroundColor: 'black',
            zIndex: '99',
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            left: '-100vw'
          }}
        >
          <div style={{fontSize:"6px",marginTop:"15px"}}><span className="metallic">NFC </span><span className="metallic"> MMA</span></div>
          <Link to="/" onClick={closeSidebar} className="sidebarLink">
            SIMULATION
          </Link>
          <Link
            to="/home"
            onClick={() =>  closeSidebar()}
            className={'sidebarLink'}
          >
            HOME
          </Link>
          <Link
            to="/card"
            onClick={() =>  closeSidebar()}
            className={'sidebarLink'}
          >
            RESULTS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/rankings">
            RANKINGS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/winners">
            ALL-TIME WINNERS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/contact">
            CONTACT
          </Link>
        </div>
 
      ) : (
        <div
          className="sidebar-nav"
          style={{
            height: '150vh',
            width: '100vw',
            backgroundColor: 'black',
            zIndex: '99',
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            left: '-100vw'
          }}
        >
          <div style={{fontSize:"6px",marginTop:"15px"}}><span className="metallic">NFC </span><span className="metallic"> MMA</span></div>
          <Link to="/" onClick={closeSidebar} className="sidebarLink">
            SIMULATION
          </Link>
          <Link
            to="/home"
            onClick={() =>  closeSidebar()}
            className={'sidebarLink'}
          >
            HOME
          </Link>
          <Link
            to="/card"
            onClick={() =>  closeSidebar()}
            className={'sidebarLink'}
          >
            RESULTS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/rankings">
            RANKINGS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/winners">
            ALL-TIME WINNERS
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/contact">
            CONTACT
          </Link>
          <Link className="sidebarLink" onClick={closeSidebar} to="/login">
            LOGIN
          </Link>
        </div>
      )}

    </div>
  )
}

/**
 * CONTAINER
 */

export default Sidebar

